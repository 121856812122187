export const isFormValid = formData => {
  let isValid = true

  for (const [key] of Object.entries(formData)) {
    if (
      key === 'isValid' ||
      key === 'isCompleted' ||
      key === 'provinces' ||
      key === 'isSectorManual'
    )
      continue
    if (formData[key].error) {
      isValid = false
    }
  }

  return isValid
}
