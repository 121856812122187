import CompleteForm from '../../../assets/complete-form.svg'
import LabTest from '../../../assets/lab-test.svg'
import Notification from '../../../assets/notification.svg'
import AppBill from '../../../assets/app-bill.svg'
import WebBill from '../../../assets/web-bill.svg'
import WhatsAppBill from '../../../assets/whatsapp-bill.svg'

const commonSteps = [
  {
    message: 'Completar formulario con informaciones requeridas por el MSP​.',
    img: CompleteForm,
    order: 1,
  },
  {
    message: '​Pasar por toma de muestra de su preferencia​.',
    img: LabTest,
    order: 4,
  },
]

const formatSteps = (steps = []) => {
  const sort = (a, b) => (a.order > b.order ? 1 : -1)

  return [...commonSteps, ...steps]
    .sort(sort)
    .map((step, current) => ({ ...step, title: `Paso ${current + 1}` }))
}

export const BILL_PROCESS = Object.freeze({
  title: 'Proceso de facturación COVID-19',
  commonSteps,
  tabs: [
    {
      title: 'App Amadita',
      steps: formatSteps([
        {
          message: 'Facturar la prueba a través de App Amadita en su celular.',
          img: AppBill,
          order: 2,
        },
        {
          message: 'Recibir su factura via App Amadita.​​',
          img: Notification,
          order: 3,
        },
      ]),
    },
    {
      title: 'Navegador Web',
      steps: formatSteps([
        {
          message:
            'Presionar el botón de facturar e iniciar el proceso de facturación.',
          img: WebBill,
          order: 2,
        },
        {
          message: 'Recibir su factura a través de la via solicitada.​​',
          img: Notification,
          order: 3,
        },
      ]),
    },
    {
      title: 'WhatsApp',
      steps: formatSteps([
        {
          message: 'Elegir proceso de facturación.',
          img: WhatsAppBill,
          order: 2,
        },
        {
          message:
            'Recibir su factura digital y ubicaciones para la toma de muestra.',
          img: Notification,
          order: 3,
        },
      ]),
    },
  ],
})
