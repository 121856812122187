import React from 'react'
import { colors } from '../../../styles/theme'

import {
  FormFillRadioButton,
  FormFillRadioInput,
  FormFillCheckIcon,
  FormFillCheck,
  FormFillCheckActive,
  FormFillRadioLabel,
  FormFillCheckBg,
} from './Style'

export const Radio = ({
  label,
  name,
  id,
  value,
  checked,
  disabled,
  ...props
}) => (
  <FormFillRadioButton
    className="form-fill-radio-button"
    {...props}
    disabled={disabled}
  >
    <FormFillRadioInput
      type="radio"
      id={id}
      name={name}
      value={value}
      defaultChecked={value === id}
      disabled={disabled}
    />
    <FormFillCheckIcon className="form-fill-check-icon">
      <FormFillCheck className="form-fill-check" />
      <FormFillCheckActive className="form-fill-check-active">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="11"
            fill={disabled ? colors.gray : colors.pink}
            stroke={disabled ? colors.gray : colors.pink}
            strokeWidth="2"
          />
          <path
            d="M7 13L11 16L17 8"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </FormFillCheckActive>
    </FormFillCheckIcon>
    <FormFillRadioLabel htmlFor={id} disabled={disabled}>
      {label}
    </FormFillRadioLabel>
    <FormFillCheckBg className="form-fill-check-bg" />
  </FormFillRadioButton>
)
