import styled from 'styled-components'
import ReactSelect from 'react-select'

export const SelectElement = styled(ReactSelect)`
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`

export const ErrorMessage = styled.div`
  ${({ validationMessage }) =>
    validationMessage &&
    `
        .form-fill__validation {
          display: block;
          margin-top: 4px;
        }
      `}
`

export const NativeSelectContainer = styled.div`
  position: relative;
`

export const NativeSelectElement = styled.select`
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  &:focus {
    border-color: #4c5a64 !important;
  }
`

export const DownArrowContainer = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  padding: 12px 24px;
  pointer-events: none;
`
