import React from 'react'
import { Description } from './Style'
import { MODAL } from './constants'
import { SimpleModal } from '../../'

export const FinalDisclaimerModal = ({
  isOpen,
  onClose = () => {},
  onClick,
}) => {
  const getFinalDisclaimerModalBody = () => (
    <>
      <Description>
        {MODAL.TEXTS.BODY.FIRST}
        <strong>{MODAL.TEXTS.BODY.SECOND}</strong>
        {MODAL.TEXTS.BODY.THIRD}
      </Description>
      <Description>
        {MODAL.TEXTS.BODY.FOURTH}
        <strong>{MODAL.TEXTS.BODY.FIFTH}</strong>
        {MODAL.TEXTS.BODY.SIXTH}
      </Description>
    </>
  )

  return (
    <SimpleModal
      isOpen={isOpen}
      body={getFinalDisclaimerModalBody()}
      title={MODAL.TEXTS.TITLE}
      buttonLabel={MODAL.TEXTS.BUTTON_LABEL}
      onCloseModal={onClose}
      onClick={onClick}
    />
  )
}
