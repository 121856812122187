import React from 'react'
import { InvisibleInput } from './style'

/**
 * This field has been added to solve a bug. If a step has only 1 input,
 * the form would submit when enter key is pressed.
 *
 * Please follow this link for more information
 * https://stackoverflow.com/questions/1370021/why-does-forms-with-single-input-field-submit-upon-pressing-enter-key-in-input
 */

export const InvisibleField = () => <InvisibleInput />
