export const isFormValid = formData => {
  let isValid = true
  const isRequestForUnderaged = formData['isRequestForMinor'].value === true
  const isFemale = formData['gender'].value === 'F'
  const isWithPassport = formData['documentType'].value === 'PASAPORTE'
  const isWithID = formData['documentType'].value === 'CEDULA'
  const canBePregnant = formData['canBePregnant'].value

  for (const [key] of Object.entries(formData)) {
    const evaluatingNonFieldData = key === 'isCompleted' || key === 'isValid'
    const evaluatingTutorName = key === 'tutorNames'
    const evaluatingPregnant = key === 'isPregnant'
    const evaluatingPassport = key === 'passport'
    const evaluatingID = key === 'id'
    const hasError = formData[key].error

    if (
      ((!isFemale || !canBePregnant) && evaluatingPregnant) ||
      evaluatingNonFieldData ||
      (isWithID && evaluatingPassport) ||
      (isWithPassport && evaluatingID)
    )
      continue

    if (!isRequestForUnderaged && evaluatingTutorName) continue
    if (hasError) {
      isValid = false
    }
  }

  return isValid
}
