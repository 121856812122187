import styled from 'styled-components'

export const TextContent = styled.div`
  color: ${({ theme: { colors } }) => colors.blackOdd};
  margin-bottom: 8px;
`

export const ListContent = styled.div`
  ul {
    margin: 0 0 24px 0;
    padding: 0;

    li {
      color: ${({ theme: { colors } }) => colors.grayDark};
      list-style: none;
      margin: 0;
      padding: 0 0 0 20px;
      position: relative;

      &::before {
        background-color: ${({ theme: { colors } }) => colors.grayDark};
        border-radius: 50%;
        content: '';
        height: 8px;
        left: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        width: 8px;
      }
    }
  }
`
