import { valueOrDefault, or } from './util'
import { getErrors } from './validation'

export const getValue = (formData, key) =>
  valueOrDefault(formData, `${key}.value`, null)
export const getValueFromOption = (formData, key) =>
  valueOrDefault(formData, `${key}.value.id`, null)
export const getLength = (formData, key) =>
  valueOrDefault(formData, `${key}.value.length`, 0)
export const hasLength = (formData, key, length) =>
  getLength(formData, key) === length
export const is = (formData, key, value) => getValue(formData, key) === value
export const isNull = (formData, key) => is(formData, key, null)
export const isEmptyString = (formData, key) => is(formData, key, '')
export const isEmptyArray = (formData, key) =>
  valueOrDefault(formData, `${key}.value.data.length`) === 0
export const isEmpty = or(isEmptyString, isNull, isEmptyArray)
export const isLongerOrEqual = (formData, key, length) =>
  getLength(formData, key) >= length

export const isFormValid = (data, questions) => {
  let isCompleted = true

  for (const question of questions) {
    if (!question.isValid()) {
      isCompleted = false
      continue
    }
  }

  return isCompleted
}

export const handleFormErrors = (
  field,
  value,
  triedToContinue,
  data,
  questions
) => {
  let message = undefined

  if (triedToContinue) {
    const question = questions.find(q => q.name === field)

    if (question != null) {
      question.updateValue(value)

      message = question.checkForErrors()
    }
  }

  return message
}
