import styled from 'styled-components'

const Disclaimer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid #e1e2e2;
  box-sizing: border-box;
  border-radius: 4px;
  color: #4c5a64;
  font-size: 16px;
  margin-bottom: 24px;

  p,
  i,
  strong {
    line-height: 24px;
  }

  h3 {
    color: #4c5a64;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 2%;
    margin-bottom: 16px;
    font-family: ${props => props.theme.fonts.tertiary};
  }

  p :not(:last-child) {
    margin-bottom: 16px;
  }

  ul {
    margin-bottom: 0;
  }

  ${props =>
    props.red &&
    `
      color: #dd2b2b;
      font-weight: bold;
    `};
`

export default Disclaimer
