import { covidTestDoneErrorValue, lastTestResultErrorValue } from './validation'

export const handleTestErrors = (field, value, triedToContinue) => {
  switch (field) {
    case 'covidTestDone':
      return covidTestDoneErrorValue(value, triedToContinue)
    case 'lastTestResult':
      return lastTestResultErrorValue(value, triedToContinue)
    default:
      return undefined
  }
}
