/*eslint-disable prettier/prettier */

import { extractNumbers, buildDateString } from '../../helpers/strings'

const getPropertyValue = property => (!!property ? property.value : '')
const getPropertyIdOrUndefined = property => getPropertyValue(property) && getPropertyValue(property).id
const getSectorValue = zone => typeof zone.value === 'string' ? zone.value : zone.value.value
const getDatePropertyValue = ({ value }) => (!!value ? buildDateString(value) : '')

export const formMapper = data => {
  const {
    steps: {
      beforeStart,
      getStarted,
      address,
      test,
      healthInsurance,
    },
  } = data

  // @TODO: Search for another place to do this
  const hasInsurance = getPropertyValue(beforeStart.hasInsurance)

  return {
    patient: {
      firstName: getPropertyValue(getStarted.names),
      lastName: getPropertyValue(getStarted.lastName),
      secondLastName: getPropertyValue(getStarted.secondLastName),
      birthdate: getPropertyValue(getStarted.birthday).toISOString(),
      documentType: getPropertyValue(getStarted.documentType),
      documentNumber: extractNumbers(getPropertyValue(getStarted.id)) || getPropertyValue(getStarted.passport),
      email: getPropertyValue(getStarted.email),
      phoneNumber: extractNumbers(getPropertyValue(getStarted.telephone)),
      cellphoneNumber: extractNumbers(getPropertyValue(getStarted.phone)),
      gender: getPropertyValue(getStarted.gender),
      nationalityId: getPropertyValue(getStarted.nationality).value,
      tutorName: getPropertyValue(getStarted.tutorNames),

      street: getPropertyValue(address.street),
      cityId: getPropertyIdOrUndefined(address.municipality),
      provinceId: getPropertyIdOrUndefined(address.province),
      sector: getSectorValue(address.zone),
      houseNumber: getPropertyValue(address.houseNumber),
      latitude: getPropertyValue(address.location).lat?.toString(),
      longitude: getPropertyValue(address.location).lng?.toString(),
      documentImage: getPropertyValue(healthInsurance.idImage).s3FileName,
      healthInsuranceImage: hasInsurance ? getPropertyValue(healthInsurance.insuranceImage).s3FileName : '',
      medicalIndicationImage: hasInsurance ? getPropertyValue(healthInsurance.medicalIndicationImage).s3FileName : '',
    },
    questions: [
      { id: 1, value: getPropertyValue(getStarted.isPregnant) },
      { id: 45, value: getPropertyValue(test.covidTestDone) },
      { id: 46, value: getPropertyValue(test.lastTestResult) },
      { id: 49, value: getPropertyValue(beforeStart.isRequestForMinor) },
      { id: 57, value: getPropertyValue(beforeStart.isAntigenTested) },
      { id: 58, value: getPropertyValue(beforeStart.antigenDate) },
      { id: 59, value: getPropertyIdOrUndefined(beforeStart.antigenResult) },
      { id: 60, value: getPropertyIdOrUndefined(beforeStart.antigenCondition) },
      { id: 61, value: getPropertyValue(beforeStart.antigenEvidence).s3FileName },
      { id: 62, value: getPropertyIdOrUndefined(test.motiveTravelCountry) },
      { id: 63, value: getPropertyIdOrUndefined(test.billFrom) },
    ],
  }
}

export const notificationMapper = data => ({
  patientId: data.patientId,
  questions: [{ id: 42, value: data.receiveNotifications }],
})
