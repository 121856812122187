import { useContext } from 'react'
import { DataContext } from '../context'
import {
  handleErrors,
  handleValidStep,
  handleCompletedStep,
} from '../screens/Form/validations'

export const useForm = stepName => {
  const { formData, setStepData } = useContext(DataContext)
  const data = formData.steps[stepName]

  const onChange = e => {
    const newData = {
      ...data,
      [e.target.name]: {
        ...data[e.target.name],
        value: e.target.value,
        error: handleErrors(
          stepName,
          e.target.name,
          e.target.value,
          data.triedToContinue,
          data
        ),
      },
    }

    return setStepData({
      [stepName]: {
        ...newData,
        isCompleted: handleCompletedStep(stepName, newData, formData.steps),
        isValid: handleValidStep(stepName, newData, formData.steps),
      },
      hasChanges: true,
    })
  }

  const setData = obj => {
    const changes = {}
    for (let i = 0; i < Object.keys(obj).length; i += 1) {
      changes[Object.keys(obj)[i]] = {
        ...data[Object.keys(obj)[i]],
        value: Object.values(obj)[i],
        error: handleErrors(
          stepName,
          Object.keys(obj)[i],
          Object.values(obj)[i],
          data.triedToContinue,
          data
        ),
      }
    }

    const newData = {
      ...data,
      ...changes,
    }

    return setStepData({
      [stepName]: {
        ...newData,
        isCompleted: handleCompletedStep(stepName, newData, formData.steps),
        isValid: handleValidStep(stepName, newData, formData.steps),
      },
      hasChanges: true,
    })
  }

  const isStepValid = () => {
    const validatedData = {}

    for (const field in data) {
      validatedData[field] = {
        ...data[field],
        error: handleErrors(stepName, field, data[field].value, true, data),
      }
    }

    const isCompleted = handleCompletedStep(
      stepName,
      validatedData,
      formData.steps
    )
    const isValid = handleValidStep(stepName, validatedData, formData.steps)

    setStepData({
      [stepName]: {
        ...validatedData,
        isCompleted,
        isValid,
        triedToContinue: true,
      },
    })
    return isCompleted && isValid
  }

  return {
    onChange,
    data,
    setData,
    isStepValid,
  }
}
