export const isFormCompleted = (formData, steps) => {
  const hasInsurance = steps.beforeStart['hasInsurance'].value
  const idImage = formData['idImage'].value.data
  const insuranceImage = formData['insuranceImage'].value.data
  const medicalIndicationImage = formData['medicalIndicationImage'].value.data

  const noIdAttached = idImage.length === 0

  const notAllDocumentsAttached =
    noIdAttached ||
    insuranceImage.length === 0 ||
    medicalIndicationImage.length === 0

  if (hasInsurance === undefined) return false
  if (noIdAttached) return false
  if (hasInsurance) {
    if (notAllDocumentsAttached) return false
  }

  return true
}
