import {
  streetErrorValue,
  provinceErrorValue,
  municipalityErrorValue,
  zoneErrorValue,
  houseNumberErrorValue,
} from './validation'

export const handleAddressErrors = (field, value, triedToContinue) => {
  switch (field) {
    case 'street':
      return streetErrorValue(value, triedToContinue)
    case 'province':
      return provinceErrorValue(value, triedToContinue)
    case 'municipality':
      return municipalityErrorValue(value, triedToContinue)
    case 'zone':
      return zoneErrorValue(value, triedToContinue)
    case 'houseNumber':
      return houseNumberErrorValue(value, triedToContinue)
    default:
      return undefined
  }
}
