import React from 'react'
import { Modal as ReactModal } from 'react-responsive-modal'

export const Modal = ({
  isOpen,
  onCloseModal,
  children,
  showCloseIcon,
  ...props
}) => (
  <ReactModal
    open={isOpen}
    blockScroll={false}
    onClose={onCloseModal}
    showCloseIcon={showCloseIcon}
    {...props}
    center
  >
    {children}
  </ReactModal>
)
