import { UPLOADER } from './constants'
import { normalizeIdInput } from './strings'
import {
  Question,
  QuestionType,
  RadioOption,
  SelectOption,
  ConditionalMessage,
} from './question'

class GenericQuestionBuilder {
  constructor(name, context) {
    this.name = name
    this.context = context
    this.model = context[name]

    this.value = this.model.value
    this.validation = this.model.error
    this.validationMessage = this.model.error
    this.visibilityConditions = []

    this.isQuestionRequired = this.model.isRequired
  }

  setLabel(label) {
    this.label = label

    return this
  }

  setDescription(description) {
    this.description = description

    return this
  }

  addValidator(validation) {
    this.validations = this.validations || []

    this.validations.push(validation)

    return this
  }

  isVisibleIf(...conditions) {
    this.visibilityConditions = this.visibilityConditions || []

    this.visibilityConditions = [...this.visibilityConditions, ...conditions]

    return this
  }

  showMessageWhen(condition, content) {
    this.messages = this.messages || []

    this.messages.push(new ConditionalMessage(condition, content))

    return this
  }

  setSizes({ xs, sm, md, lg, xl }) {
    this.xs = xs
    this.sm = sm
    this.md = md
    this.lg = lg
    this.xl = xl

    return this
  }

  setOnChangeHandler(onChange) {
    this.onChange = onChange

    return this
  }

  build() {
    const show = this.visibilityConditions.reduce((shouldShow, condition) => {
      if (!shouldShow) return false

      return condition(this.context)
    }, true)

    return new Question({
      show,
      context: this.context,
      value: this.value,
      name: this.name,
      label: this.label,
      description: this.description,
      isRequired: this.isQuestionRequired,
      validations: this.validations,
      messages: this.messages,
      xs: this.xs,
      sm: this.sm,
      md: this.md,
      lg: this.lg,
      xl: this.xl,
      type: this.type,
      validation: this.validation,
      validationMessage: this.validationMessage,
    })
  }
}

class OptionsQuestionBuilder extends GenericQuestionBuilder {
  constructor(name, context) {
    super(name, context)

    this.options = []
  }

  build() {
    const question = super.build()
    question.value = this.value
    question.options = this.options

    return question
  }
}

class RadioGroupQuestionBuilder extends OptionsQuestionBuilder {
  constructor(name, context) {
    super(name, context)
    this.type = QuestionType.RADIO_GROUP
  }

  addOption(label, id) {
    this.options = this.options || []

    this.options.push(new RadioOption(label, id))

    return this
  }

  isBoolean() {
    this.isBooleanValue = true

    return this
  }

  isTrueWhenSelectionIs(value) {
    this.compareValue = value

    return this
  }

  build() {
    const question = super.build()
    question.onChange = e => {
      this.isBooleanValue
        ? this.onChange(e.target.id === this.compareValue)
        : this.onChange(e.target.id)
    }

    return question
  }
}

class SelectQuestionBuilder extends OptionsQuestionBuilder {
  constructor(name, context) {
    super(name, context)
    this.type = QuestionType.SELECT
  }

  addOption(label, id) {
    this.options = this.options || []

    this.options.push(new SelectOption(label, id))

    return this
  }

  setPlaceholder(placeholder) {
    this.placeholder = placeholder

    return this
  }

  build() {
    const question = super.build()
    question.onChange = item => this.onChange(item)
    question.placeholder = this.placeholder

    return question
  }
}

class FieldQuestionBuilder extends GenericQuestionBuilder {
  constructor(name, context) {
    super(name, context)
    this.type = QuestionType.FIELD
  }

  setMaxLength(maxLength) {
    this.maxLength = maxLength

    return this
  }

  setPlaceholder(placeholder) {
    this.placeholder = placeholder

    return this
  }

  build() {
    const question = super.build()

    question.maxLength = this.maxLength
    question.onChange = e => this.onChange(e.target.value)
    question.value = this.value
    question.placeholder = this.placeholder

    return question
  }
}

class CedulaQuestionBuilder extends FieldQuestionBuilder {
  constructor(name, context) {
    super(name, context)
    this.type = QuestionType.FIELD

    this.setPlaceholder('123-4567890-1')
  }

  build() {
    const question = super.build()

    question.maxLength = this.maxLength

    question.onChange = e =>
      this.onChange(normalizeIdInput(e.target.value, question.value))
    question.value = this.value

    return question
  }
}

class DatePickerQuestionBuilder extends GenericQuestionBuilder {
  constructor(name, context) {
    super(name, context)
    this.type = QuestionType.DATEPICKER
  }

  setDateFormat(dateFormat) {
    this.dateFormat = dateFormat

    return this
  }

  showMonthDropdown() {
    this.shouldShowMonthDropdown = true

    return this
  }

  showYearDropdown() {
    this.shouldShowYearDropdown = true

    return this
  }

  useScrollableYearDropdown() {
    this.shouldScrollableYearDropdown = true

    return this
  }

  showYearDropdownItemNumber() {
    this.shouldYearDropdownItemNumber = true

    return this
  }

  setMaxDate(maxDate) {
    this.maxDate = maxDate

    return this
  }

  dontAllowFutureDates() {
    return this.setMaxDate(new Date())
  }

  isMonthYearPicker() {
    this.isMonthYear = true

    return this
  }

  setPlaceholder(placeholder) {
    this.placeholder = placeholder

    return this
  }

  build() {
    const question = super.build()

    question.maxLength = this.maxLength
    question.onChange = this.onChange
    question.dateFormat = this.isMonthYear ? 'MM/yyyy' : this.dateFormat
    question.showMonthYearPicker = this.isMonthYear
    question.showFullMonthYearPicker = this.isMonthYear
    question.showMonthDropdown = this.shouldShowMonthDropdown
    question.showYearDropdown = this.shouldShowYearDropdown
    question.scrollableYearDropdown = this.shouldScrollableYearDropdown
    question.yearDropdownItemNumber = this.shouldYearDropdownItemNumber
    question.maxDate = this.maxDate
    question.placeholder = this.placeholder

    return question
  }
}

class UploaderQuestionBuilder extends GenericQuestionBuilder {
  constructor(name, context) {
    super(name, context)
    this.type = QuestionType.UPLOADER
    this.selectedFiles = []
    this.acceptedFiles = []
  }

  addSupportFor(...extensions) {
    this.acceptedFiles = this.acceptedFiles || []

    this.acceptedFiles = [...this.acceptedFiles, ...extensions]

    return this
  }

  setSelectedFiles(selectedFiles) {
    this.selectedFiles = selectedFiles

    return this
  }

  setFileLimit(fileLimit) {
    this.fileLimit = fileLimit

    return this
  }

  enableCompression() {
    this.enableCompression = true

    return this
  }

  setOnChangeHandler(onChange) {
    this.onChange = onChange

    return this
  }

  build() {
    const question = super.build()

    const onDrop = files => this.onChange({ ...question.value, data: files })
    const onClean = () => this.onChange({ ...question.value, data: [] })

    question.acceptedFiles = this.acceptedFiles.join(',')
    question.selectedFiles = this.value.data
    question.fileLimit = this.fileLimit || UPLOADER.FILELIMIT
    question.enableCompression = this.enableCompression
    question.onFilesDrop = onDrop
    question.onFileRemove = onClean

    return question
  }
}

export class QuestionBuilder {
  static RaidioGroup = RadioGroupQuestionBuilder
  static Field = FieldQuestionBuilder
  static Cedula = CedulaQuestionBuilder
  static DatePicker = DatePickerQuestionBuilder
  static Select = SelectQuestionBuilder
  static Uploader = UploaderQuestionBuilder
}
