export const validator = (isValid, message = '') => (...args) => [
  isValid(...args),
  !isValid(...args) ? message : '',
]

export const getErrors = (formData, question) => {
  if (question.show === false) return ''

  const { validations } = question

  if (validations && validations.length) {
    for (const valilation of validations) {
      const [isValid, message] = valilation(formData, question.name)
      if (!isValid) return message
    }
  }

  return ''
}

export const isQuestionValid = (formData, question) => {
  if (question.show === false) return true

  const { validations } = question

  if (validations && validations.length) {
    for (const valilation of validations) {
      const [isValid] = valilation(formData, question.name)
      if (!isValid) return false
    }
  }

  return true
}
