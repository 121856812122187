// eslint-disable-next-line no-unused-vars
import styled, { css, StyledComponent, keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const TabsContainer = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
`

export const TabsHeader = styled.ul`
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 8px 8px 0 0;
  display: flex;
  margin: 0 0 16px 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  margin: 24px 0;

  ${({ theme: { media } }) => media.tablet`
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover {
      display: none;
    }
  `}

  ${({ isResponsiveRow, theme: { media, colors } }) =>
    isResponsiveRow
      ? media.tablet(`
    flex-wrap: wrap;
    border-radius: 8px;
    padding: 8px 16px;

    li {
      min-width: 100%;

      &:after {
        display: none;
      }

      button {
        &,
        &:active,
        &:focus,
        &:hover,
        &:focus:active,
        &:not(:disabled):active {
          box-shadow: none;
          border-color: ${colors.grayLight};
        }
      }

      &:last-child button {
        &,
        &:active,
        &:focus,
        &:hover,
        &:focus:active,
        &:not(:disabled):active {
          border-bottom: none;
        }
      }
    }
  `)
      : ''};
`

export const TabsHeaderTabItem = styled.li`
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;

  &::after {
    content: '';
    width: 1px;
    min-height: 20px;
    height: 50%;
    position: absolute;
    right: 0;
    background-color: ${({ theme: { colors } }) => colors.grayLight};
  }

  &:last-child::after {
    display: none;
  }
`

export const TabsHeaderTabTitle = styled.button`
  align-items: center;
  border-width: 0;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  min-height: 39px;
  justify-content: center;
  line-height: 24px;
  padding: 8px 24px;
  position: relative;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isActive, theme: { colors } }) => css`
    &,
    &:active,
    &:focus,
    &:hover,
    &:focus:active,
    &:not(:disabled):active {
      background-color: ${isActive ? colors.secondary : 'transparent'};
      box-shadow: 0 0 0 0 transparent;
      color: ${isActive ? colors.pink : colors.grayDark};
      outline: none;
      text-decoration: none;
      font-weight: ${isActive ? 'bold' : 'inherit'};
      box-shadow: ${isActive ? `inset 0 -1px 0 0 ${colors.pink}` : 'none'};
      border-bottom: solid 1px ${isActive ? colors.pink : colors.grayLight};
    }
  `}
`

/**
 * Tab component
 *
 * @type {StyledComponent<'div', {title: string;}>}
 */
export const Tab = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  animation: ${fadeIn} 0.5s ease-in-out;
`
