import React, { useState } from 'react'
import { NoOptionsContainer, ButtonContainer, Button } from './style'

export const NoOptions = ({ onClick, message, buttonMessage }) => {
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    setClicked(true)
    onClick()
  }

  return clicked ? null : (
    <NoOptionsContainer>
      <p>{message}</p>
      {onClick && (
        <ButtonContainer onClick={handleClick}>
          <Button>{buttonMessage}</Button>
        </ButtonContainer>
      )}
    </NoOptionsContainer>
  )
}
