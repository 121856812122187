// Before Start
import {
  isBeforeStartValid,
  handleBeforeStartErrors,
} from '../steps/before-start'

// Get Started
import { handleGetStartedErrors } from '../steps/get-started/errorhandler'
import { handleTestErrors } from '../steps/test/errorhandler'
import { isFormCompleted as isGetStartedCompleted } from '../steps/get-started/completedStatus'
import { isFormValid as isGetStartedValid } from '../steps/get-started/validStatus'
import { isFormCompleted as isTestCompleted } from '../steps/test/completedStatus'
import { isFormValid as isTestValid } from '../steps/test/validStatus'

// Address
import { handleAddressErrors } from '../steps/address/errorhandler'
import { isFormCompleted as isAddressCompleted } from '../steps/address/completedStatus'
import { isFormValid as isAddressValid } from '../steps/address/validStatus'

// healthIsurance
import { handleHealthInsuranceErrors } from '../steps/health-insurance/errorhandler'
import { isFormCompleted as isHealthInsuranceCompleted } from '../steps/health-insurance/completedStatus'
import { isFormValid as isHealthInsuranceValid } from '../steps/health-insurance/validStatus'

import { STEPS_NAME } from '../models'

export const handleErrors = (step, field, value, triedToContinue, data) => {
  switch (step) {
    case STEPS_NAME.BEFORE_START:
      return handleBeforeStartErrors(field, value, triedToContinue, data)
    case STEPS_NAME.GET_STARTED:
      return handleGetStartedErrors(field, value, triedToContinue, data)
    case STEPS_NAME.TEST:
      return handleTestErrors(field, value, triedToContinue, data)
    case STEPS_NAME.ADDRESS:
      return handleAddressErrors(field, value, triedToContinue, data)
    case STEPS_NAME.HEALTH_INSURANCE:
      return handleHealthInsuranceErrors(field, value, triedToContinue, data)
    default:
      return undefined
  }
}

export const handleCompletedStep = (step, formData, steps) => {
  switch (step) {
    case STEPS_NAME.BEFORE_START:
      return isBeforeStartValid(formData, steps)
    case STEPS_NAME.GET_STARTED:
      return isGetStartedCompleted(formData, steps)
    case STEPS_NAME.TEST:
      return isTestCompleted(formData, steps)
    case STEPS_NAME.ADDRESS:
      return isAddressCompleted(formData, steps)
    case STEPS_NAME.HEALTH_INSURANCE:
      return isHealthInsuranceCompleted(formData, steps)
    default:
      return undefined
  }
}

export const handleValidStep = (step, formData, steps) => {
  switch (step) {
    case STEPS_NAME.BEFORE_START:
      return isBeforeStartValid(formData, steps)
    case STEPS_NAME.GET_STARTED:
      return isGetStartedValid(formData, steps)
    case STEPS_NAME.TEST:
      return isTestValid(formData, steps)
    case STEPS_NAME.ADDRESS:
      return isAddressValid(formData, steps)
    case STEPS_NAME.HEALTH_INSURANCE:
      return isHealthInsuranceValid(formData, steps)
    default:
      return undefined
  }
}
