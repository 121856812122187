import React from 'react'
import { Spinner } from '../../components/Spinner'
import { StepsWrapper } from './Style'
import { STEPS_NAME } from './models'

// Steps
import { BeforeStart } from './steps/before-start'
import { GetStarted } from './steps/get-started'
import { Address } from './steps/address'
import { Test } from './steps/test'
import { NUMBER_TO_STEP_NAME } from '../Form/models'
import { HealthInsurance } from './steps/health-insurance'

export const index = formName => NUMBER_TO_STEP_NAME.indexOf(formName)

const STEPS = {
  [index(STEPS_NAME.BEFORE_START)]: BeforeStart,
  [index(STEPS_NAME.GET_STARTED)]: GetStarted,
  [index(STEPS_NAME.ADDRESS)]: Address,
  [index(STEPS_NAME.HEALTH_INSURANCE)]: HealthInsurance,
  [index(STEPS_NAME.TEST)]: Test,
}

export const Steps = ({ currentStep }) => {
  const Step = STEPS[currentStep]

  return (
    <StepsWrapper>
      {Step ? <Step form={NUMBER_TO_STEP_NAME[currentStep]} /> : <Spinner />}
    </StepsWrapper>
  )
}
