export const addressModel = {
  street: { value: '', error: '', isRequired: true },
  province: { value: 0, error: '', isRequired: true },
  municipality: { value: 0, error: '', isRequired: true },
  zone: { value: '', error: '', isRequired: true },
  houseNumber: { value: '', error: '', isRequired: true },
  isSectorManual: { value: false, error: '' },
  isValid: true,
  isCompleted: false,
  triedToContinue: false,
}
