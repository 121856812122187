import styled from 'styled-components'

export const TextContent = styled.div`
  color: ${({ theme: { colors } }) => colors.blackOdd};
  margin-bottom: 8px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  button {
    min-width: 218px;
  }
  @media (max-width: 574px) {
    display: block;
    button {
      width: 100%;

      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
`
