import React from 'react'
import { Col } from 'reactstrap'
import { Checkbox } from '../forms/Checkbox'
import { CheckBoxGroup } from '../forms/CheckboxGroup'
import { DatePicker } from '../forms/DatePicker'
import { Field } from '../forms/Field'
import { FormFill } from '../forms/FormFill'
import { RadioGroup } from '../forms/RadioGroup'
import { Radio } from '../forms/Radio'
import { Select } from '../forms/Select'
import { TextArea } from '../forms/TextArea'
import { FilePicker } from '../forms/FilePicker'
import Disclaimer from '../Disclaimer'
import { QuestionType } from '../../helpers/question'

const Controls = {
  [QuestionType.CHECKBOX]: Checkbox,
  [QuestionType.CHECKBOX_GROUP]: CheckBoxGroup,
  [QuestionType.DATEPICKER]: DatePicker,
  [QuestionType.FIELD]: Field,
  [QuestionType.FORMFILL]: FormFill,
  [QuestionType.RADIO_GROUP]: RadioGroup,
  [QuestionType.RADIO]: Radio,
  [QuestionType.SELECT]: Select,
  [QuestionType.TEXTAREA]: TextArea,
  [QuestionType.UPLOADER]: FilePicker,
}

export const Question = ({
  type,
  show,
  xs,
  sm,
  md,
  lg,
  xl,
  messages,
  context,
  ...props
}) => {
  const Control = Controls[type]

  if (show === false) return <></>

  return (
    <>
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Control {...props} />
      </Col>
      {messages &&
        messages.map(message => (
          <Col xs={12}>
            {typeof message === 'string' && (
              <Disclaimer
                dangerouslySetInnerHTML={{ __html: message }}
              ></Disclaimer>
            )}
            {message.isVisible(context) && (
              <Disclaimer
                red
                dangerouslySetInnerHTML={{ __html: message.content }}
              ></Disclaimer>
            )}
          </Col>
        ))}
    </>
  )
}
