function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

function mapCompressToUploader(file) {
  const mbInBytes = 1000000
  return {
    name: file.alt,
    path: file.alt,
    data: file.data,
    size: file.initialSizeInMb * mbInBytes,
    type: file.ext,
    compressedSize: file.endSizeInMb * mbInBytes,
  }
}

export { formatBytes, mapCompressToUploader }
