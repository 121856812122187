import React from 'react'

import { PCR_NOTICE_MODAL } from '../constants'
import { SimpleModal } from '../../../components/Modal'
import { Description } from './Style'

export const PCRNoticeModal = ({
  isOpen,
  onClose,
  onClick,
  isFormFinished,
}) => {
  const { TITLE, BUTTON_LABEL, DESCRIPTION } = PCR_NOTICE_MODAL

  const {
    FIRST,
    THANKS,
    DIGITAL_BILL,
    WHATSAPP_BILL,
    FORM_FINISHED,
    PRESENTIAL_BILL,
    FORM_NOT_FINISHED,
  } = DESCRIPTION

  const getModalBody = () => (
    <>
      <Description>
        {isFormFinished ? FORM_FINISHED : FORM_NOT_FINISHED}
        {FIRST}
        <ol>
          <li>{DIGITAL_BILL()}</li>
          <li>{PRESENTIAL_BILL()}</li>
          <li>{WHATSAPP_BILL()}</li>
        </ol>
      </Description>
      <Description>{THANKS}</Description>
    </>
  )

  return (
    <SimpleModal
      isOpen={isOpen}
      body={getModalBody()}
      onCloseModal={onClose}
      onClick={onClick}
      title={TITLE}
      buttonLabel={BUTTON_LABEL}
    />
  )
}
