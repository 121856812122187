import styled, { keyframes } from 'styled-components'
const animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Fade = styled.div`
  animation: ${animation} 0.7s;
`
