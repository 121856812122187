import { createContext } from 'react'
import { formModel } from '../screens/Form/models'

export const DataContext = createContext({
  fetchedData: {},
  setFormData: () => {},
  setStepData: () => {},
  handleSubmit: () => {},
  formData: { ...formModel },
  uploadHealthInsuranceImages: () => {},
  uploadAntigenTestEvidence: () => {},
})
