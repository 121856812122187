import styled from 'styled-components'
import { colors } from '../../../styles/theme'

export const Container = styled.div`
  text-align: center;
`

export const Code = styled.div`
  padding: 16px 0;
  margin: 0 0 2.5rem 0;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  border: solid 1px ${colors.grayLight};
  font-family: 'Inter';
`
