import React from 'react'
import { FormFillTextArea, FormFill } from './Style'
import {
  FormFillLabel,
  FormRequired,
  FormFillValidationMessage,
} from '../Style'

export const TextArea = ({
  children,
  isRequired,
  label,
  name,
  onChange,
  validationMessage,
  value,
  noResize,
  ...props
}) => {
  return (
    <FormFill htmlFor={name} className="form-fill">
      <FormFillLabel>
        {label} {isRequired ? <FormRequired>*</FormRequired> : null}
      </FormFillLabel>
      <FormFillTextArea
        name={name}
        onChange={onChange}
        value={value}
        resize={noResize && 'none'}
        {...props}
      />
      {children}
      {validationMessage && (
        <FormFillValidationMessage className="form-fill__validation">
          {validationMessage}
        </FormFillValidationMessage>
      )}
    </FormFill>
  )
}
