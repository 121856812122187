import React from 'react'
import {
  FormFillCheckButton,
  FormFillCheckIcon,
  FormFillCheck,
  FormFillCheckActive,
  FormFillCheckBoxLabel,
  FormFillCheckBg,
} from './Style'

export const Checkbox = ({ label, id, children }) => (
  <FormFillCheckButton className="form-fill-check-button">
    {children}
    <FormFillCheckIcon className="form-fill-check-icon">
      <FormFillCheck className="form-fill-check" />
      <FormFillCheckActive className="form-fill-check-active">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="11"
            fill="#ED2B74"
            stroke="#ED2B74"
            strokeWidth="2"
          />
          <path
            d="M7 13L11 16L17 8"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </FormFillCheckActive>
    </FormFillCheckIcon>
    <FormFillCheckBoxLabel htmlFor={id}>{label}</FormFillCheckBoxLabel>
    <FormFillCheckBg className="form-fill-check-bg" />
  </FormFillCheckButton>
)
