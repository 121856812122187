import { ERROR, YES_ANSWER } from './constants'
import { beforeStartModel } from '../../models/beforeStart'
import { hourDiff } from '../../../../helpers/dates'
import { and, or, not } from '../../../../helpers/util'
import { validator } from '../../../../helpers/validation'
import {
  getValue,
  getValueFromOption,
  hasLength,
  is,
  isEmpty,
  isLongerOrEqual,
} from '../../../../helpers/form'

import {
  DOCUMENTTYPE_QUESTION,
  ANTIGEN_QUESTION,
  ANTIGEN_RESULT_QUESTION,
  ANTIGEN_CONDITION_QUESTION,
  ANTIGEN_DATE_QUESTION,
  CEDULA_ANSWER,
  PASAPORTE_ANSWER,
  POSITIVE_ANSWER,
  NEGATIVE_ANSWER,
  CONDITION_ONE_ANSWER,
  CONDITION_TWO_ANSWER,
  CONDITION_THREE_ANSWER,
} from './constants'

const MINIMUM_ID_LENGTH = 13
const MINIMUM_PASSPORT_LENGTH = 6

const getResult = data => getValueFromOption(data, ANTIGEN_RESULT_QUESTION)
const getCondition = data =>
  getValueFromOption(data, ANTIGEN_CONDITION_QUESTION)
const isMarkedAsRequired = (_, key) => beforeStartModel[key].isRequired
const isDocumentTypeEmpty = formData => isEmpty(formData, DOCUMENTTYPE_QUESTION)
const isDocumentTypeCedula = formData =>
  is(formData, DOCUMENTTYPE_QUESTION, CEDULA_ANSWER)
const isDocumentTypePassport = formData =>
  is(formData, DOCUMENTTYPE_QUESTION, PASAPORTE_ANSWER)
const isValidLengthForCedula = (formData, key) =>
  hasLength(formData, key, MINIMUM_ID_LENGTH)
const isValidLengthForPassport = (formData, key) =>
  isLongerOrEqual(formData, key, MINIMUM_PASSPORT_LENGTH)
const isCedulaSelected = and(
  not(isDocumentTypeEmpty),
  not(isDocumentTypeCedula)
)
const isPositiveAntigenResult = result => result === POSITIVE_ANSWER
const isNegtiveAntigenResult = result => result === NEGATIVE_ANSWER
const isAntigenConditionOne = (_, condition) =>
  condition === CONDITION_ONE_ANSWER
const isAntigenConditionTwo = (_, condition) =>
  condition === CONDITION_TWO_ANSWER
const isAntigenConditionThree = (_, condition) =>
  condition === CONDITION_THREE_ANSWER
const isInvalidCombinationOne = and(
  isPositiveAntigenResult,
  isAntigenConditionOne
)
const isInvalidCombinationTwo = and(
  isNegtiveAntigenResult,
  isAntigenConditionTwo
)
const isInvalidCombinationThree = and(
  isNegtiveAntigenResult,
  isAntigenConditionThree
)
const isAntigenDateQuestionEmpty = formData =>
  isEmpty(formData, ANTIGEN_DATE_QUESTION)
const isUnderAmountOfHours = (date, hours) =>
  hourDiff(new Date(), new Date(date)) < hours
const isLessThan72Hours = formData =>
  isUnderAmountOfHours(getValue(formData, ANTIGEN_DATE_QUESTION), 72)
const isAValidCombination = not(
  or(
    isInvalidCombinationOne,
    isInvalidCombinationTwo,
    isInvalidCombinationThree
  )
)

const isRequired = or(not(isMarkedAsRequired), not(isEmpty))
const isValidCedula = or(isCedulaSelected, isValidLengthForCedula)
const isValidPassport = or(
  not(isDocumentTypePassport),
  isValidLengthForPassport
)
const isAntigenTested = formData => is(formData, ANTIGEN_QUESTION, YES_ANSWER)
export const isConditionSupported = formData =>
  isAValidCombination(getResult(formData), getCondition(formData))

export const isAntigenLessThan72Hours = or(
  isAntigenDateQuestionEmpty,
  isLessThan72Hours
)

export const isRequiredValidator = validator(
  isRequired,
  ERROR.EMPTY.REQUIRED_INFO
)
export const isAntigenTestedValidator = validator(isAntigenTested)
export const isValidCedulaValidator = validator(isValidCedula, ERROR.INVALID.ID)
export const isValidPassportValidator = validator(
  isValidPassport,
  ERROR.INVALID.PASSPORT
)
export const isConditionSupportedValidator = validator(isConditionSupported)
export const isAntigenLessThan72HoursValidator = validator(
  isAntigenLessThan72Hours
)
