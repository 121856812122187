export const isFormValid = formData => {
  let isValid = true
  const isFirstTest = formData['covidTestDone'].value === false

  for (const [key] of Object.entries(formData)) {
    const evaluatingLastTestResult = key === 'lastTestResult'

    if (key === 'isValid' || key === 'isCompleted') continue
    if (isFirstTest && evaluatingLastTestResult) continue

    if (formData[key].error) {
      isValid = false
    }
  }

  return isValid
}
