import React, { memo, useContext, useMemo, useState } from 'react'
import constants from './constants'

import { useAmaditaApp } from '../../hooks/useAmaditaApp'
import { DataContext } from '../../context'
import { CodeContainer } from './CodeContainer'
import { Container, Col, Row } from 'reactstrap'
import { FinalDisclaimerModal } from '../../components/Modal/implementations'
import {
  PageTitle,
  StoreLinks,
  PageSubTitle,
  ThanksContainer,
  MobileAppIncentive,
  FollowSocialNetworks,
  BillFromWrapper,
} from './Style'
import { Button } from '../../components/Button'

export const Thanks = memo(({ onFinish }) => {
  const {
    formData: {
      code,
      steps: {
        getStarted: { names },
      },
    },
  } = useContext(DataContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const { isAmaditaApp, sendMessageToApp } = useAmaditaApp()

  const onBillFrom = () => sendMessageToApp({ goToBill: true })

  const renderMobileAppIncentive = () => (
    <MobileAppIncentive>
      <p>{constants.APP_INCENTIVE_DESC}</p>
      <StoreLinks>
        {constants.STORES.map(({ image, url }, index) => (
          <a href={url} target="_blank" rel="noopener noreferrer" key={index}>
            <img src={image} alt={'download-badge'} />
          </a>
        ))}
      </StoreLinks>
    </MobileAppIncentive>
  )

  const renderSocialNetworksContainer = () => (
    <FollowSocialNetworks>
      <p>Siguenos en nuestras redes sociales</p>
      <ul>
        {constants.SOCIAL_NETWORKS.map(({ icon, url }, i) => (
          <li key={i}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <div dangerouslySetInnerHTML={{ __html: icon }} />
            </a>
          </li>
        ))}
      </ul>
    </FollowSocialNetworks>
  )

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12}>
            <ThanksContainer>
              <PageTitle>{`${constants.NAME} ${names.value}!`}</PageTitle>
              <PageSubTitle>
                {isAmaditaApp
                  ? constants.CODE_DESCRIPTION_APP
                  : constants.CODE_DESCRIPTION0}
              </PageSubTitle>
              <CodeContainer code={code} />
              {isAmaditaApp && (
                <BillFromWrapper>
                  <Button primary onClick={onBillFrom} maxContent>
                    {constants.BILL_APP}
                  </Button>
                </BillFromWrapper>
              )}
              {renderMobileAppIncentive()}
              {renderSocialNetworksContainer()}
            </ThanksContainer>
          </Col>
        </Row>
      </Container>
      <FinalDisclaimerModal
        isOpen={isModalOpen}
        onClick={() => {
          setModalOpen(false)
          onFinish()
        }}
      />
    </>
  )
})
