export const hourDiff = (date1, date2) => {
  const diff = Math.floor(date1.getTime() - date2.getTime())
  const hour = 1000 * 60 * 60

  return diff / hour
}

export const yearDiff = (date1, date2) => {
  const diff = Math.floor(date1.getTime() - date2.getTime())
  const day = 1000 * 60 * 60 * 24

  const days = diff / day
  const months = days / 31

  return months / 12
}
