/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react'
import { Row, Col } from 'reactstrap'
import compress from 'compress.js'

import { useForm } from '../../../../hooks/useForm'
import { DataContext } from '../../../../context/dataContext'
import constants from '../../../../helpers/constants'
import { TEXTS, UPLOADER } from './constants'

import Disclaimer from '../../../../components/Disclaimer'
import { FormFill } from '../../../../components/forms/FormFill'
import { StepWrapper } from '../../../../components/StepWrapper'
import { Uploader } from '../../../../components/Uploader'
import {
  MaxImagesModal,
  UnsupportedFileTypeModal,
} from '../../../../components/Modal/implementations'
import { mapCompressToUploader } from './helpers'
import { STEPS_NAME } from '../../models'

const { HEALTH_INSURANCE } = constants.FORMS

export const HealthInsurance = ({ form }) => {
  const [
    isFileTypeUnsupportedModalOpen,
    setFileTypeUnsupportedModalOpen,
  ] = useState(false)
  const [isMaxImagesModalOpen, setMaxImagesModalOpen] = useState(false)

  const { formData } = useContext(DataContext)
  const { data, setData } = useForm(form)
  const { data: beforeStartData } = useForm(STEPS_NAME.BEFORE_START)
  const { idImage, insuranceImage, medicalIndicationImage } = data
  const { hasInsurance } = beforeStartData
  const { isRequestForMinor } = formData.steps.beforeStart
  const Compress = new compress()

  const toggleMaxImagesModal = () =>
    setMaxImagesModalOpen(!isMaxImagesModalOpen)

  const toggleFileTypeUnsupportedModal = () =>
    setFileTypeUnsupportedModalOpen(!isFileTypeUnsupportedModalOpen)

  const onDrop = async (acceptedFiles, field) => {
    const compressedFiles = await Compress.compress(acceptedFiles, {
      size: 4,
      quality: 0.4,
    })
    const formattedFiles = compressedFiles.map(file =>
      mapCompressToUploader(file)
    )

    if (formattedFiles.length === UPLOADER.FILELIMIT) {
      setData({
        [field]: {
          ...data[field].value,
          data: formattedFiles,
        },
      })
    }
  }

  const handleFilesRemove = field => {
    setData({ [field]: { ...data[field].value, data: [] } })
  }

  const isDisclaimerVisible = isRequestForMinor.value || hasInsurance.value

  return (
    <>
      <Row>
        <Col xs={12}>
          <StepWrapper title={HEALTH_INSURANCE} />
        </Col>
        {isDisclaimerVisible && (
          <Disclaimer>
            <h3>{TEXTS.CONSIDERATIONS}</h3>
            {isRequestForMinor.value && (
              <p style={{ marginBottom: 12 }}>
                <strong>{TEXTS.MINOR.HIGHLIGHT}</strong>
                {TEXTS.MINOR.PARAGRAPH}
              </p>
            )}
            {hasInsurance.value && (
              <p>
                <strong>{TEXTS.INSURANCE.HIGHLIGHT}</strong>
                {TEXTS.INSURANCE.PARAGRAPH}
              </p>
            )}
          </Disclaimer>
        )}
        {hasInsurance.value !== undefined && (
          <>
            <Col xs={9} md={6}>
              <FormFill
                isRequired
                validation={idImage.error}
                label="Imagen cédula/pasaporte"
              >
                <Uploader
                  fileLimit={UPLOADER.FILELIMIT}
                  onFileLimitReached={toggleMaxImagesModal}
                  onFileTypeUnsupported={toggleFileTypeUnsupportedModal}
                  onFilesDrop={files => onDrop(files, 'idImage')}
                  acceptedFiles=".jpg,.png,.jpeg"
                  selectedFiles={idImage.value.data}
                  onFileRemove={() => handleFilesRemove('idImage')}
                />
              </FormFill>
            </Col>
            {hasInsurance.value && (
              <>
                <Col xs={9} md={6}>
                  <FormFill
                    isRequired
                    validation={insuranceImage.error}
                    label="Imagen carnet del seguro médico"
                  >
                    <Uploader
                      fileLimit={UPLOADER.FILELIMIT}
                      onFileLimitReached={toggleMaxImagesModal}
                      onFileTypeUnsupported={toggleFileTypeUnsupportedModal}
                      onFilesDrop={files => onDrop(files, 'insuranceImage')}
                      acceptedFiles=".jpg,.png,.jpeg"
                      selectedFiles={insuranceImage.value.data}
                      onFileRemove={() => handleFilesRemove('insuranceImage')}
                    />
                  </FormFill>
                </Col>
                <Col xs={9} md={6}>
                  <FormFill
                    isRequired
                    validation={medicalIndicationImage.error}
                    label="Imagen indicación médica"
                  >
                    <Uploader
                      fileLimit={UPLOADER.FILELIMIT}
                      onFileLimitReached={toggleMaxImagesModal}
                      onFilesDrop={files =>
                        onDrop(files, 'medicalIndicationImage')
                      }
                      onFileTypeUnsupported={toggleFileTypeUnsupportedModal}
                      acceptedFiles=".jpg,.png,.jpeg"
                      selectedFiles={medicalIndicationImage.value.data}
                      onFileRemove={() =>
                        handleFilesRemove('medicalIndicationImage')
                      }
                    />
                  </FormFill>
                </Col>
              </>
            )}
          </>
        )}
      </Row>
      <MaxImagesModal
        isOpen={isMaxImagesModalOpen}
        onClose={toggleMaxImagesModal}
        onClick={toggleMaxImagesModal}
      />
      <UnsupportedFileTypeModal
        isOpen={isFileTypeUnsupportedModalOpen}
        onClose={toggleFileTypeUnsupportedModal}
        onClick={toggleFileTypeUnsupportedModal}
      />
    </>
  )
}
