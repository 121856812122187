import styled from 'styled-components'

export const FormFillText = styled.input`
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.blackOdd : theme.colors.black};
`

export const FormFill = styled.label`
  font-family: ${({ theme: { fonts } }) => fonts.tertiary};
  margin: 0 0 24px 0;
  padding: 0;
  width: ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};

  ${({ theme: { media } }) => media.tablet`
    width: 100%;
  `}

  input,
  input:not([type='submit']),
  input:not([type='reset']),
  select {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    opacity: ${({ disabled, theme }) => disabled && theme.opacity.disabled};
    border-radius: 12px;
    border: 2px solid ${({ theme: { colors } }) => colors.grayLight};
    background-color: ${({ disabled }) => disabled && '#FFFFFF'};
    box-sizing: border-box;
    padding: 12px 24px;
    width: 100%;
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.blackOdd : theme.colors.defaultTextColor};
    transition: all 0.2s ease-in-out;
    margin: 0;
    line-height: normal;
    height: 56px;

    &::placeholder {
      color: ${({ theme: { colors } }) => colors.gray};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme: { colors } }) => colors.grayDark};
    }

    &:hover {
      border: ${({ theme, disabled }) =>
        `2px solid ${!disabled && theme.colors.grayDark}}`};
    }

    ${({ validationMessage, theme: { colors } }) =>
      validationMessage &&
      `
      input {
        border-color: ${colors.required} !important;
      }

      .form-fill__validation {
        display: block;
        margin-top: 4px;
      }
    `}
  }
`
