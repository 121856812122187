import styled from 'styled-components'

export const DividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`

export const Line = styled.div`
  ${({ theme }) => `
    border-top: 1px solid ${theme.colors.grayLight};
  `}
  height: 1px;
  width: 100%;
  margin: 0.75em 0;
  align-content: center;
`

export const TextContainer = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.grayDark};
    font-family: ${theme.fonts.roboto};
  `}
  padding: 0 0.75em;
  line-height: 1.5em;
`
