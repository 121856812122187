import React from 'react'
import { render } from 'react-dom'
import * as Sentry from '@sentry/react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-responsive-modal/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_NODE_ENV,
})

render(<App />, document.getElementById('app'))
serviceWorker.unregister()
