import React, { memo } from 'react'
import { getPercentage } from '../../helpers/getPercentage'
import {
  StepOfProgress,
  StepOfText,
  StepOfProgressBar,
  StepOfWrapper,
} from './Style'

export const StepOf = memo(({ currentStep, steps }) => (
  <StepOfWrapper>
    {!!currentStep && (
      <>
        <StepOfText>
          Paso {currentStep} de {steps}
        </StepOfText>
        <StepOfProgress>
          <StepOfProgressBar progress={getPercentage(currentStep, steps)} />
        </StepOfProgress>
      </>
    )}
  </StepOfWrapper>
))
