import React, { useState, useCallback, useEffect } from 'react'
import {
  TabsContainer,
  TabsHeader,
  TabsHeaderTabItem,
  TabsHeaderTabTitle,
  Tab,
} from './Style'

const tabsWarnings = children => {
  if (process.env.NODE_ENV === 'production') return

  const isMultiple = children && children?.length >= 2

  /**
   * @throws It doesn't allow the use of this component without
   * providing at least two or more tabs.
   */
  if (!isMultiple)
    throw new Error(
      "Oops, this component works with 2 or more tabs, consider using another option if you don't need 2 or more tabs."
    )

  const tabValidations = child => {
    if (child?.type !== Tab)
      throw new Error('A Tabs can only contain "Tab" as its direct children')

    if (!child.props?.title)
      throw new Error('The title prop is required for each tab')
  }

  /**
   * @throws Don't allow the use of this component if your
   * children are not a tab component.
   */
  React.Children.toArray(children).forEach(tabValidations)
}

export const TabTitle = ({ title, setTab, index, isActive }) => {
  const onClick = useCallback(() => setTab(index), [setTab, index])

  return (
    <TabsHeaderTabItem>
      <TabsHeaderTabTitle {...{ isActive, onClick }}>
        {title}
      </TabsHeaderTabTitle>
    </TabsHeaderTabItem>
  )
}

/**
 * Tabs component
 *
 * @param {Object} props
 * @param {number} props.defaultSelected
 * @param {VoidFunction} props.onSelect
 * @param {ReactNode} props.children
 * @param {boolean} props.isResponsiveRow
 */
export const Tabs = ({
  children,
  onSelect,
  isResponsiveRow,
  defaultSelected = 0,
}) => {
  tabsWarnings(children)

  const [tab, setTab] = useState(defaultSelected)

  useEffect(() => {
    onSelect && onSelect(tab)
  }, [tab, onSelect])

  const renderTab = useCallback(
    ({ props: { title } }, index) => (
      <TabTitle
        key={`tab-${index}`}
        isActive={tab === index}
        {...{ title, index, setTab }}
      />
    ),
    [tab]
  )

  return (
    <TabsContainer>
      <TabsHeader {...{ isResponsiveRow }}>
        {children?.map(renderTab)}
      </TabsHeader>

      {children[tab]}
    </TabsContainer>
  )
}
