export const ERROR = {
  EMPTY: {
    REQUIRED_INFO: 'Esta información es requerida',
  },
  INVALID: {
    ID: 'Cédula inválida',
    PASSPORT: 'Pasaporte inválido',
    RESULT_CONDITION: 'Combinación de resultado y condición inválida',
  },
}

export const constants = {
  MODAL: {
    HAS_REQUEST: {
      TITLE: 'El paciente ya tiene una solicitud en proceso',
      BODY: 'Una vez completada, podrá someter otra solicitud.',
    },
    NOT_VALID: {
      TITLE: 'Documento inválido',
      BODY: 'El documento introducido no es válido.',
    },
    ERROR: {
      TITLE: 'Error inesperado',
      BODY: 'Los servidores están temporalmente fuera de servicio, por favor intenta más tarde.',
    },
    UNDERAGED: {
      TITLE: 'Cédula de menor de edad',
      BODY: 'Si el paciente es menor de edad, coloca la cédula de uno de sus padres o tutor.',
    },
    BUTTON_LABEL: 'Ok, volver',
  },
}

export const PREGNANT_AGE = {
  MIN: 12,
  MAX: 60,
}

export const YEAR_IN_SECONDS = 3.15576e10

export const IS_MINOR_QUESTION = 'isRequestForMinor'
export const DOCUMENTTYPE_QUESTION = 'documentType'
export const ID_QUESTION = 'id'
export const PASSPORT_QUESTION = 'passport'
export const IS_INSURED_QUESTION = 'hasInsurance'
export const ANTIGEN_QUESTION = 'isAntigenTested'
export const ANTIGEN_DATE_QUESTION = 'antigenDate'
export const ANTIGEN_RESULT_QUESTION = 'antigenResult'
export const ANTIGEN_CONDITION_QUESTION = 'antigenCondition'
export const ANTIGEN_EVIDENCE_QUESTION = 'antigenEvidence'

export const IS_MINOR_ANSWER = 'isMinor'
export const IS_NOT_MINOR_ANSWER = 'isNotMinor'
export const CEDULA_ANSWER = 'CEDULA'
export const PASAPORTE_ANSWER = 'PASAPORTE'
export const YES_ANSWER = 'YES'
export const NO_ANSWER = 'NO'
export const POSITIVE_ANSWER = 'POSITIVE'
export const NEGATIVE_ANSWER = 'NEGATIVE'
export const ASEGURADO_ANSWER = 'ASEGURADO'
export const PRIVADO_ANSWER = 'PRIVADO'
export const CONDITION_ONE_ANSWER = 'PACIENTE CON SIGNOS Y SINTOMAS'
export const CONDITION_TWO_ANSWER =
  'ASINTOMATICO CON CONTACTO CON PERSONA POSITIVA'
export const CONDITION_THREE_ANSWER =
  'ASINTOMÁTICO, PERO SIN CONTACTO CON PERSONA POSITIVA'

export const PRC_DATE_MESSAGE = `<span><strong>Nota:</strong> La autorización dependerá de su aseguradora.</span>`
export const ANTIGEN_TEST_MESSAGE = `Según las disposiciones gubernamentales resolución 00237-2021 necesita una prueba antigenos menor a 72 horas para que  la PCR sea cubierta por la aseguradora. Para seguir el proceso de PCR como privado seleccione Privado en la pregunta 3. <br />`
export const CONDITION_MESSAGE = `Su condición no aplica para una PCR asegurada según la resolución 00237-2021. <br />
            <strong> Para seguir el proceso de PCR como privado seleccione Privado en la pregunta 3.</strong>`
export const EVIDENCE_MESSAGE = `
            Si desea facturar en la sucursal debe llevar el documento físicamente.
            <br />
            <br />
            Si desea facturar por APP debe enviar el documento al correo callcenter@amadita.com
            con el asunto “Antígeno y nombre del paciente” o por Whatsapp empresarial 8096825414
            identificándose con el nombre del paciente.
          `
export const LABELS = {
  IS_REQUEST_FOR_MINOR: '¿La solicitud es para un paciente menor de edad?',
  DOCUMENTTYPE: 'Tipo de documento',
  ID: 'Documento de identidad',
  IS_INSURED: '¿El paciente es asegurado o privado?',
  ANTIGEN: '¿Se realizó una prueba de antígeno?',
  ANTIGEN_DATE: 'Fecha de realización de antígeno',
  ANTIGEN_RESULT: 'Resultado de prueba de antígeno',
  ANTIGEN_CONDITION: '¿Cuál es su condición?',
  ANTIGEN_EVIDENCE: 'Resultado del antígeno',
  CONDITION_ONE: 'Paciente con signos y síntomas',
  CONDITION_TWO: 'Asintomático con contacto con persona positiva',
  CONDITION_THREE: 'Asintomático, pero sin contacto con persona positiva',

  POSITIVE: 'Positivo',
  NEGATIVE: 'Negativo',
  YES: 'Sí',
  NO: 'No',
  INSURED: 'Asegurado',
  PRIVATE: 'Privado',
  CEDULA: 'Cédula',
  PASSPORT: 'Pasaporte',
}

export const DESCRIPTIONS = {
  IS_REQUEST_FOR_MINOR:
    'Si es menor de edad, coloca el documento de identidad de uno de sus padres o tutor.',
}

export const PLACEHOLDERS = {
  CEDULA: '123-4567890-1',
  PASSPORT: '12345678901A',
  DATE: 'DD/MM/AAAA',
  SELECT: 'Selecciona',
}
