import styled from 'styled-components'

export const Form = styled.div`
  position: relative;
  min-height: 369px;
  padding: 32px 40px;
  border: 2px solid #e1e2e2;
  box-sizing: border-box;
  border-radius: 16px;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 991.98px) {
    margin-bottom: 20px;
    padding: 0;
    border: 0;
  }
`

export const StepsWrapper = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
`
