import { ERROR } from './constants'

const composedCheck = condition => (fieldValue, triedToContinue) => {
  if (triedToContinue && condition(fieldValue)) {
    return ERROR.EMPTY.REQUIRED_INFO
  }
  return undefined
}

const noItems = array => array.length === 0

export const documentsErrorValue = composedCheck(noItems)
