import styled from 'styled-components'
import { colors } from '../../styles/theme'

export const ThanksContainer = styled.div`
  display: flex;
  max-width: 735px;
  margin: 40px auto;
  padding: 32px 40px;
  position: relative;
  border-radius: 16px;
  flex-direction: column;
  box-sizing: border-box;
  border: 2px solid #e1e2e2;
  justify-content: space-between;

  ${({ theme: { media } }) => media.tablet`
    margin-bottom: 20px;
    padding: 0;
    border: 0;
  `}

  .form-fill {
    margin: 0 0 40px 0;
  }
`

export const PageTitle = styled.h2`
  margin-bottom: 24px;
  text-align: center;
`

export const PageSubTitle = styled.p`
  margin-bottom: 0;
  padding-bottom: 2rem;
  color: ${colors.blackOdd};
`

export const Text = styled.p`
  color: ${colors.blackOdd};
  margin: 0;
`

export const LastStepContainer = styled.div`
  margin-bottom: 2.5rem;
`

export const FollowSocialNetworks = styled.div`
  align-items: center;
  border-top: 1px solid ${({ theme: { colors } }) => colors.grayLight};
  margin: 24px 0 0 0;
  padding: 24px 0 0 0;
  width: 100%;

  & > p {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  & > ul {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      margin: 0 6px;
      list-style: none;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      align-items: center;
      display: flex;
      height: 72px;
      justify-content: center;
      width: 72px;
    }
  }
`

export const MobileAppIncentive = styled.div`
  text-align: center;

  & > p {
    margin-bottom: 16px;
  }
`
export const StoreLinks = styled.div`
  display: flex;
  margin: auto;
  max-width: 458px;
  margin-bottom: 8px;
  justify-content: space-between;

  a:not(:last-child) {
    padding-right: 16px;
  }
`

export const BillFromWrapper = styled.div`
  margin: auto;
  margin-bottom: 40px;
`
