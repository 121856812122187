import {
  nameErrorValue,
  genderErrorValue,
  lastNameErrorValue,
  nationalityErrorValue,
  secondLastNameErrorValue,
  birthDateErrorValue,
  emailErrorValue,
  phoneErrorValue,
  telephoneErrorValue,
  pregnantErrorValue,
} from './validation'

export const handleGetStartedErrors = (field, value, triedToContinue) => {
  switch (field) {
    case 'names':
      return nameErrorValue(value, triedToContinue)
    case 'lastName':
      return lastNameErrorValue(value, triedToContinue)
    case 'secondLastName':
      return secondLastNameErrorValue(value, triedToContinue)
    case 'gender':
      return genderErrorValue(value, triedToContinue)
    case 'nationality':
      return nationalityErrorValue(value, triedToContinue)
    case 'birthday':
      return birthDateErrorValue(value, triedToContinue)
    case 'email':
      return emailErrorValue(value, triedToContinue)
    case 'phone':
      return phoneErrorValue(value, triedToContinue)
    case 'telephone':
      return telephoneErrorValue(value, triedToContinue)
    case 'isPregnant':
      return pregnantErrorValue(value, triedToContinue)
    case 'tutorNames':
      return nameErrorValue(value, triedToContinue)
    default:
      return undefined
  }
}
