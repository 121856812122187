import styled from 'styled-components'

export const Description = styled.div`
  margin-bottom: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

export const CaptchaContainer = styled.div`
  display: flex;
  justify-content: center;
`
