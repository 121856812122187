export const emptyIfNullOrUndefined = string => (string == null ? '' : string)

export const isNameValid = string => {
  if (!string || !string.length) {
    return false
  }
  const letterRegex = /^[a-zA-ZáéíóúñÑ]+(([',. -][a-zA-ZáéíóúñÑ ])?[a-zA-ZáéíóúñÑ]*)*$/
  return letterRegex.test(string)
}

export const isPlaceNameValid = string => {
  if (!string || !string.length) {
    return false
  }
  const lettersAndNumbersRegex = /^[ A-Za-z0-9áéíóúñÑ/\-#.]*$/
  return lettersAndNumbersRegex.test(string)
}

export const isHouseNumberValid = string => {
  if (!string || !string.length) {
    return false
  }
  const lettersAndNumbersRegex = /^[ A-Za-z0-9/\-#.]*$/
  return lettersAndNumbersRegex.test(string)
}

export const charIsNumber = char => {
  return char >= '0' && char <= '9'
}

export const isCompletelyNumber = text => {
  let onlyHasNumbers = true
  // eslint-disable-next-line array-callback-return
  text.split('').map(char => {
    if (!charIsNumber(char)) {
      onlyHasNumbers = false
    }
  })
  return onlyHasNumbers
}

export const isEmailValid = string => {
  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(string)
}

// Extracted from StackOverflow https://stackoverflow.com/questions/55988065/react-how-to-format-phone-number-as-user-types
export const normalizePhoneInput = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`
  }
}

// normalizeFeverInput
export const normalizeFeverInput = (value, previousValue) => {
  // return nothing if no value
  if (value.length <= previousValue.length) {
    return `${value.slice(0, value.length - 1)}°`
  }

  if (!value) return value

  // only allows 0-9 inputs and '.'
  const currentValue = value.replace(/[^\d'.']/g, '')

  return `${currentValue}°`
}

export const isValidDecimalNumber = number => {
  const decimalNumberRegex = /^(0|[1-9]\d*)(\.\d+)?$/
  return decimalNumberRegex.test(number)
}

export const normalizeText = (value, previousValue) => {
  if (!value) return value

  if (typeof value === 'string') {
    return value.trim()
  }
  return value
}

export const normalizeNumber = (value = '') => value.replace(/[^\d]/g, '')

export const normalizeIdInput = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length

  if (cvLength > 11) return previousValue

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue

    if (cvLength < 11)
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`

    return `${currentValue.slice(0, 3)}-${currentValue.slice(
      3,
      10
    )}-${currentValue.slice(10)}`
  }
}

export const normalizeMonthYearInput = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length

  if (cvLength > 6) return previousValue

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 2) return currentValue

    return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`
  }
}

export const extractNumbers = value => value.replace(/\D/g, '')

export const normalizeString = string =>
  string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

/**
 * @param {Date} date
 * @returns {String} dateString
 */
export const buildDateString = date => {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return `${year}-${month}-${day}`
}
