export const getStartedModel = {
  names: { value: '', error: '', isRequired: true },
  lastName: { value: '', error: '', isRequired: true },
  secondLastName: { value: '', error: '', isRequired: false },
  birthday: { value: '', error: '', isRequired: true },
  email: { value: '', error: '', isRequired: true },
  phone: { value: '', error: '', isRequired: true },
  telephone: { value: '', error: '', isRequired: false },
  id: { value: '', error: '', isRequired: true },
  passport: { value: '', error: '', isRequired: true },
  nationality: { value: 0, error: '', isRequired: true },
  gender: { value: '', error: '', isRequired: true },
  isPregnant: { value: '', error: '', isRequired: true },
  documentType: { value: 0, error: '', isRequired: true },
  isRequestForMinor: { value: false, error: '', isRequired: false },
  tutorNames: { value: '', error: '', isRequired: true },
  canBePregnant: { value: false, error: '', isRequired: false },
  isValid: true,
  isCompleted: false,
  triedToContinue: false,
}
