import { YEAR_IN_SECONDS, PREGNANT_AGE } from './constants'

/**
 * returns desired datepicker limit based on person's age range.
 * @param {'top' | 'bottom'} limit
 * @param {true | false} isUnderaged
 */
export const getDateLimit = (limit, isUnderaged) => {
  const today = new Date()
  const eighteenYearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  )

  const getMaxBirthDate = () => {
    if (!isUnderaged) {
      return eighteenYearsAgo
    }
    return today
  }

  const getMinBirthDate = () => {
    if (isUnderaged) {
      return eighteenYearsAgo
    }
  }

  if (limit === 'top') return getMaxBirthDate()
  if (limit === 'bottom') return getMinBirthDate()
}

export const getAge = birthDate =>
  Math.floor((new Date() - new Date(birthDate).getTime()) / YEAR_IN_SECONDS)

export const isAgeBetween = (age, minAge, maxAge) =>
  age >= minAge && age < maxAge

export const isInPregnancyAge = birthDate =>
  isAgeBetween(getAge(birthDate), PREGNANT_AGE.MIN, PREGNANT_AGE.MAX)
