import { ERROR } from './constants'
import {
  emptyIfNullOrUndefined,
  isPlaceNameValid,
  isHouseNumberValid,
} from '../../../../helpers/strings'

export const streetErrorValue = (fieldValue, triedToContinue) => {
  const streetText = emptyIfNullOrUndefined(fieldValue).trim()

  if (triedToContinue && streetText === '') {
    return ERROR.EMPTY.REQUIRED_INFO
  }

  return undefined
}

export const provinceErrorValue = (fieldValue, triedToContinue) => {
  const provinceId = fieldValue && fieldValue.value

  if (triedToContinue && provinceId === 0) {
    return ERROR.EMPTY.REQUIRED_INFO
  }

  return undefined
}

export const municipalityErrorValue = (fieldValue, triedToContinue) => {
  const municipalityId = fieldValue && fieldValue.value
  if (triedToContinue && municipalityId === 0) {
    return ERROR.EMPTY.REQUIRED_INFO
  }

  return undefined
}

export const zoneErrorValue = (fieldValue, triedToContinue) => {
  const isFromTextfield = typeof fieldValue === 'string'
  if (isFromTextfield) {
    const zoneText = emptyIfNullOrUndefined(fieldValue).trim()
    const isEmpty = value => value === ''
    const isInvalid = value => !isEmpty(value) && !isPlaceNameValid(value)

    if (triedToContinue) {
      if (isEmpty(zoneText)) return ERROR.EMPTY.REQUIRED_INFO
      if (isInvalid(zoneText)) return ERROR.INVALID.SECTOR
    }
    return undefined
  } else {
    const zoneId = fieldValue?.value

    if (triedToContinue && zoneId === 0) {
      return ERROR.EMPTY.REQUIRED_INFO
    }
    return undefined
  }
}

export const houseNumberErrorValue = (fieldValue, triedToContinue) => {
  const houseNumberText = emptyIfNullOrUndefined(fieldValue).trim()
  const isEmpty = value => value === ''
  const isInvalid = value => !isEmpty(value) && !isHouseNumberValid(value)

  if (triedToContinue) {
    if (isEmpty(houseNumberText)) return ERROR.EMPTY.REQUIRED_INFO
    if (isInvalid(houseNumberText)) return ERROR.INVALID.HOUSE_NUMBER
  }

  return undefined
}
