/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'

import { Form } from './Style'
import { FormContext } from './Context'
import { ScreensContext } from '../Context'
import { DataContext } from '../../context'
import { useForm } from '../../hooks/useForm'
import { extractNumbers } from '../../helpers/strings'
import { sleep } from '../../helpers/util'
import { idVerification } from '../../services/handlers'
import { validateIdVerificationResult } from './validations/idValidation'

// Models
import { Thanks } from '../Thanks'
import { Steps } from './Steps'
import { Container, Col, Row } from 'reactstrap'
import { Header } from '../../components/Header'
import { Prompt } from '../../components/Prompt'
import { Spinner } from '../../components/Spinner'
import { SimpleModal } from '../../components/Modal'
import { FormFooter } from '../../components/FormFooter'
import { formModel, NUMBER_TO_STEP_NAME, STEPS_NAME } from './models'
import { PCRNoticeModal } from '../Home/PCRNoticeModal'
import constants, { MODAL_ERRORS_CODE, INVALID_ELEMENTS_CLASS } from '../../helpers/constants'

export const FormScreen = ({ isLoading, setLoading }) => {
  const [formStatus, setFormStatus] = useState({
    steps: NUMBER_TO_STEP_NAME.length - 1,
    currentStep: 0,
    isComplete: false,
  })
  const { currentStep, steps } = formStatus
  const setStep = currentStep => setFormStatus({ ...formStatus, currentStep })
  const {
    screen,
    setScreen,
    setErrorModal,
    isErrorModalOpen,
    setBeforeThanksModal,
    isBeforeThanksModalOpen,
  } = useContext(ScreensContext)
  const {
    formData,
    setFormData,
    handleSubmit,
    uploadHealthInsuranceImages,
    uploadAntigenTestEvidence,
  } = useContext(DataContext)
  const { isStepValid } = useForm(NUMBER_TO_STEP_NAME[currentStep])
  const [visibleModal, setVisibleModal] = useState('')

  useEffect(() => {
    setFormStatus(state =>
      state.currentStep === state.steps
        ? {
            ...state,
            isComplete: true,
          }
        : { ...state, isComplete: false }
    )
  }, [currentStep])

  const verifyDocument = async () => {
    setLoading(true)
    const { id, passport, documentType, isRequestForMinor } =
      formData.steps.getStarted
    const isWithId = documentType.value === constants.CEDULA

    const documentData = {
      document: isWithId ? extractNumbers(id.value) : passport.value,
      documentType: documentType.value,
    }
    const result = await idVerification(documentData)
    setLoading(false)
    return validateIdVerificationResult(result, isRequestForMinor.value)
  }

  const nextStep = () =>
    currentStep >= steps ? setStep(steps) : setStep(currentStep + 1)

  const prevStep = () =>
    currentStep <= 1 ? setScreen(null) : setStep(currentStep - 1)

  const goToStart = () => {
    setStep(0)
    setScreen(null)
    setFormData(formModel)
  }

  const handleNextClick = async () => {
    if (!isStepValid()) {
      await untilErrorsRender()
      window.scrollBy(0, getFirstInvalidElementOffset())
      return
    }
    await onValidStep()
  }

  const untilErrorsRender = async () => await sleep(0.9)

  const getFirstInvalidElementOffset = () => {
    const elements = getInvalidElements()
    if (elements.length === 0) return 0

    return getOffsetToFitControl(elements[0].getBoundingClientRect().top)
  }

  const getInvalidElements = () => getErrors().filter(ignoreEmpty)

  const getOffsetToFitControl = offset => (offset < 200 ? offset - 200 : 0)

  const getErrors = () => {
    // TODO: Investigate a better way to get the elements.
    //       All the implementations I found required to make a significant refactor.
    return Array.from(document.getElementsByClassName(INVALID_ELEMENTS_CLASS))
  }

  const ignoreEmpty = element => element.innerText !== ''

  const onValidStep = async () => {
    try {
      if (currentStep === 0) {
        const verificationResult = await verifyDocument()
        if (verificationResult) setVisibleModal(verificationResult)
        else {
          setLoading(true)
          await uploadAntigenTestEvidence()
          setLoading(false)
          nextStep()
        }
      } else {
        const isHealthInsuranceStep =
          NUMBER_TO_STEP_NAME[currentStep] === STEPS_NAME.HEALTH_INSURANCE

        if (isHealthInsuranceStep) {
          setLoading(true)
          await uploadHealthInsuranceImages()
          setLoading(false)
        }
        nextStep()
      }
      window.scrollTo(0, 0)
    } catch (e) {
      setErrorModal(MODAL_ERRORS_CODE.UPLOAD_HEALTH_INSURANCES_IMAGES)
      setLoading(false)
    }
  }

  const formContextValues = {
    prevStep,
    setLoading,
    formStatus,
    visibleModal,
    setVisibleModal,
  }

  const renderThanks = () => <Thanks onFinish={goToStart} />

  const renderForm = () => (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8}>
            <Form>
              {isLoading && <Spinner />}
              <Steps currentStep={currentStep} />
              <FormFooter
                handleNextClick={handleNextClick}
                submitForm={handleSubmit}
              />
            </Form>
          </Col>
        </Row>
        <Prompt hasChanges={formData.hasChanges} />
      </Container>
    </>
  )

  return (
    <FormContext.Provider value={{ ...formContextValues }}>
      <Header />
      {screen === 'thanks' ? renderThanks() : renderForm()}
      {/**
       * @TODO: Move all modals to modal/implementations and create a controller
       * near the root of the app
       */}
      <SimpleModal
        code={isErrorModalOpen}
        isOpen={isErrorModalOpen}
        body={constants.MODAL.ERROR.BODY}
        title={constants.MODAL.ERROR.TITLE}
        onClick={() => setErrorModal(0)}
        onCloseModal={() => setErrorModal(0)}
        buttonLabel={constants.MODAL.ERROR.BUTTON_LABEL}
      />
      <PCRNoticeModal
        isOpen={isBeforeThanksModalOpen}
        isFormFinished
        onClick={() => {
          setBeforeThanksModal(false)
          setScreen('thanks')
        }}
      />
    </FormContext.Provider>
  )
}
