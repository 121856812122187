import { testModel } from '../../models/test'

export const isFormCompleted = formData => {
  let isFormComplete = true
  const isFirstTest = formData['covidTestDone'].value === false

  for (const [key, value] of Object.entries(formData)) {
    const evaluatingLastTestResult = key === 'lastTestResult'

    if (!testModel[key].isRequired || value.value !== '') continue
    if (isFirstTest && evaluatingLastTestResult) continue
    return false
  }
  return isFormComplete
}
