export const ERROR = {
  EMPTY: {
    REQUIRED_INFO: 'Esta información es requerida',
  },
}

export const TEXTS = {
  CONSIDERATIONS: 'Informaciones a considerar antes de subir los documentos:',
  MINOR: {
    HIGHLIGHT: 'Si el paciente es menor de edad',
    PARAGRAPH:
      ', subir documento de identidad de uno de sus padres o tutor. Debe ser el mismo que agregó al inicio de este formulario.',
  },
  INSURANCE: {
    HIGHLIGHT: '',
    PARAGRAPH:
      'La indicación médica debe contener únicamente la prueba del COVID-19, estar vigente, firmada y sellada por su médico.',
  },
}

export const UPLOADER = {
  FILELIMIT: 1,
}
