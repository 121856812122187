import React from 'react'
import { FixedSizeList as List } from 'react-window'

const height = 45

export const MenuList = ({
  options,
  children,
  maxHeight,
  getValue,
  selectProps: { noOptionsMessage },
}) => {
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  return !!children.length ? (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  ) : (
    noOptionsMessage()
  )
}
