import React from 'react'
import MaskedTextInput from 'react-text-mask'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'

import {
  FormFillLabel,
  FormRequired,
  FormFillValidationMessage,
} from '../Style'
import { getISOStringDateFormat } from '../../../helpers/util'
import { FormFill } from './Style'

registerLocale('es', es)

const mobileDeviceSize = 768
const isMediumSizeDevice = window.innerWidth <= mobileDeviceSize
const defaultDateFormat = 'dd/MM/yyyy'
const defaultMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

export const DatePicker = ({
  children,
  isRequired,
  label,
  name,
  type,
  onChange,
  onClick,
  widthAuto,
  validationMessage,
  value,
  placeholderText,
  dateFormat = defaultDateFormat,
  mask = defaultMask,
  ...props
}) => {
  return (
    <FormFill
      htmlFor={name}
      widthAuto={widthAuto}
      className="form-fill"
      validationMessage={validationMessage}
    >
      <FormFillLabel>
        {label} {isRequired ? <FormRequired>*</FormRequired> : null}
      </FormFillLabel>
      {isMediumSizeDevice ? (
        <>
          <input
            type="date"
            placeholder={placeholderText}
            onChange={e => onChange(e.target.valueAsDate)}
            style={{ backgroundColor: 'white' }}
            min={getISOStringDateFormat(props.minDate)}
            max={getISOStringDateFormat(props.maxDate)}
          />
        </>
      ) : (
        <ReactDatePicker
          name={name}
          value={value}
          onChange={onChange}
          locale="es"
          dateFormat={dateFormat}
          placeholderText={placeholderText}
          customInput={
            <MaskedTextInput type="text" guide={false} mask={mask} />
          }
          {...props}
        />
      )}
      {children}
      {validationMessage && (
        <FormFillValidationMessage className="form-fill__validation">
          {validationMessage}
        </FormFillValidationMessage>
      )}
    </FormFill>
  )
}
