import { getStartedModel } from '../../models/getStarted'

export const isFormCompleted = formData => {
  let isCompleted = true
  const isRequestForUnderaged = formData['isRequestForMinor'].value === true
  const isFemale = formData['gender'].value === 'F'
  const isWithPassport = formData['documentType'].value === 'PASAPORTE'
  const isWithID = formData['documentType'].value === 'CEDULA'
  const canBePregnant = formData['canBePregnant'].value

  for (const [key, value] of Object.entries(formData)) {
    const evaluatingNonFieldData = key === 'isCompleted' || key === 'isValid'
    const evaluatingTutorName = key === 'tutorNames'
    const evaluatingPregnant = key === 'isPregnant'
    const evaluatingPassport = key === 'passport'
    const evaluatingID = key === 'id'
    const notRequiredField = !getStartedModel[key].isRequired
    const isFieldEmpty = value.value === ''

    if (
      evaluatingNonFieldData ||
      ((!isFemale || !canBePregnant) && evaluatingPregnant) ||
      (isWithID && evaluatingPassport) ||
      (isWithPassport && evaluatingID)
    )
      continue

    if ((!isRequestForUnderaged && evaluatingTutorName) || notRequiredField)
      continue
    if (isFieldEmpty) {
      isCompleted = false
    }
  }

  return isCompleted
}
