import React, { useContext, useRef } from 'react'
import constants from '../../../../helpers/constants'
import { useForm } from '../../../../hooks/useForm'
import {
  normalizePhoneInput,
  normalizeIdInput,
} from '../../../../helpers/strings'
import { getDateLimit, isInPregnancyAge } from './helpers'

// Components
import { ERROR } from './constants'
import { Row, Col } from 'reactstrap'
import { DataContext } from '../../../../context'
import { MenuList } from '../../../../components/List'
import { Field } from '../../../../components/forms/Field'
import { Radio } from '../../../../components/forms/Radio'
import { Select } from '../../../../components/forms/Select'
import { StepWrapper } from '../../../../components/StepWrapper'
import { FormFill } from '../../../../components/forms/FormFill'
import { DatePicker } from '../../../../components/forms/DatePicker'
import { NoOptions } from '../../../../components/forms/Select/NoOptions'
import { InnerFormTextWrapper } from '../../../../components/InnerFormTextWrapper'

const { GET_STARTED } = constants.FORMS
export const GetStarted = ({ form }) => {
  const { formData, fetchedData } = useContext(DataContext)
  const { isRequestForMinor } = formData.steps.beforeStart
  const { data, onChange, setData } = useForm(form)
  const { nationalities } = fetchedData
  const {
    id,
    canBePregnant,
    passport,
    documentType,
    names,
    lastName,
    secondLastName,
    birthday,
    nationality,
    gender,
    isPregnant,
    tutorNames,
    email,
    phone,
    telephone,
  } = data

  const selectRef = useRef()

  const onNationalitySelect = nationality => setData({ nationality })

  const handlePhoneChange = (field, currentValue, { target: { value } }) => {
    return setData({ [field]: normalizePhoneInput(value, currentValue) })
  }

  const handleIdChange = (field, currentValue, { target: { value } }) => {
    return setData({ [field]: normalizeIdInput(value, currentValue) })
  }

  const handleDatePickerChange = birthday => {
    const canBePregnant = isInPregnancyAge(birthday)
    setData({ birthday, canBePregnant })
  }

  const renderDocumentRow = () => (
    <>
      <Col xs={'auto'} lg={'auto'}>
        <FormFill
          label="Tipo de documento"
          validation={documentType.error}
          isRequired
          disabled
        >
          <Radio
            disabled
            id="CEDULA"
            label="Cédula"
            name="documentType"
            value={documentType.value}
            checked={documentType.value === 'CEDULA'}
            onChange={e => setData({ documentType: e.target.id })}
          />
          <Radio
            disabled
            id="PASAPORTE"
            label="Pasaporte"
            name="documentType"
            value={documentType.value}
            checked={documentType.value === 'PASAPORTE'}
            onChange={e => setData({ documentType: e.target.id })}
          />
        </FormFill>
      </Col>
      {documentType.value && documentType.value === 'CEDULA' && (
        <Col width={'100%'}>
          <Field
            disabled
            validationMessage={id.error}
            label={'Documento de identidad'}
            value={id.value}
            name="id"
            isRequired
            placeholder="123-4567890-1"
            onChange={e => handleIdChange('id', id.value, e)}
          />
        </Col>
      )}
      {documentType.value && documentType.value === 'PASAPORTE' && (
        <Col width={'100%'}>
          <Field
            disabled
            validationMessage={passport.error}
            label={'Documento de identidad'}
            value={passport.value}
            name="passport"
            maxLength={44}
            isRequired
            placeholder="12345678901A"
            onChange={onChange}
          />
        </Col>
      )}
    </>
  )

  const renderExtraInfo = () => (
    <>
      <Col xs={12} lg={6}>
        <Select
          label="Nacionalidad"
          value={nationality.value}
          placeholder="&#xF002; Buscar"
          options={nationalities}
          onChange={onNationalitySelect}
          validationMessage={nationality.error}
          ref={selectRef}
          additionalComponents={{ MenuList }}
          forceDesktopComponent
          isRequired
          searchable
          noOptionsMessage={<NoOptions message={ERROR.NOT_FOUND} />}
        />
      </Col>
      <Col xs={12}>
        <FormFill label="Género" validation={gender.error} isRequired>
          <Radio
            id="M"
            name="gender"
            label="Masculino"
            value={gender.value}
            checked={gender.value === 'M'}
            onChange={e => setData({ gender: e.target.id })}
          />
          <Radio
            id="F"
            name="gender"
            label="Femenino"
            value={gender.value}
            checked={gender.value === 'F'}
            onChange={e => setData({ gender: e.target.id })}
          />
        </FormFill>
      </Col>
      {gender.value === 'F' && canBePregnant.value === true ? (
        <Col xs={12}>
          <FormFill
            label="¿Está embarazada?"
            validation={isPregnant.error}
            isRequired
          >
            <Radio
              label="Sí, lo estoy"
              name="isPregnant"
              id="isPregnantYes"
              value={isPregnant.value}
              checked={isPregnant.value}
              onChange={() => setData({ isPregnant: true })}
            />
            <Radio
              label="No, no lo estoy"
              name="isPregnant"
              id="isPregnantNo"
              value={isPregnant.value}
              checked={isPregnant.value === false && isPregnant.value !== ''}
              onChange={() => setData({ isPregnant: false })}
            />
          </FormFill>
        </Col>
      ) : null}
    </>
  )

  return (
    <Row>
      <Col xs={12} style={{ paddingBottom: 16 }}>
        <StepWrapper title={GET_STARTED} />
      </Col>
      {isRequestForMinor.value === false && renderDocumentRow()}
      <Col xs={12}>
        <Field
          validationMessage={names.error}
          label="Nombres"
          value={names.value}
          name="names"
          isRequired
          placeholder="Juan Camilo"
          onChange={onChange}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Field
          validationMessage={lastName.error}
          label="Primer apellido"
          value={lastName.value}
          name="lastName"
          isRequired
          placeholder="Pérez"
          onChange={onChange}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Field
          validationMessage={secondLastName.error}
          label="Segundo apellido"
          value={secondLastName.value}
          name="secondLastName"
          placeholder="Jiménez"
          onChange={onChange}
        />
      </Col>
      <Col xs={12} lg={6}>
        <DatePicker
          validationMessage={birthday.error}
          label="Fecha de nacimiento"
          selected={birthday.value}
          name="birthday"
          isRequired
          placeholderText="DD/MM/AAAA"
          onChange={handleDatePickerChange}
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}
          maxDate={getDateLimit('top', isRequestForMinor.value)}
          minDate={getDateLimit('bottom', isRequestForMinor.value)}
        />
      </Col>
      {isRequestForMinor.value === true && (
        <>
          {renderExtraInfo()}
          <Col xs={12}>
            <InnerFormTextWrapper>
              <p>
                {constants.GET_STARTED.MINOR1}
                <strong>{constants.GET_STARTED.MINOR2}</strong>
              </p>
            </InnerFormTextWrapper>
          </Col>
          {renderDocumentRow()}
          <Col xs={12} lg={6}>
            <Field
              validationMessage={tutorNames.error}
              label="Nombres"
              value={tutorNames.value}
              name="tutorNames"
              isRequired
              placeholder="Antonio Mirabal"
              onChange={e => onChange(e)}
            />
          </Col>
        </>
      )}
      <Col xs={12} lg={6}>
        <Field
          validationMessage={email.error}
          label="Correo electrónico"
          value={email.value}
          name="email"
          type="email"
          isRequired
          placeholder="cuenta@email.com"
          onChange={onChange}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Field
          validationMessage={phone.error}
          label="Celular"
          value={phone.value}
          name="phone"
          isRequired
          placeholder="(809) 555-4444"
          onChange={e => handlePhoneChange('phone', phone.value, e)}
          type="tel"
        />
      </Col>
      <Col xs={12} lg={6}>
        <Field
          validationMessage={telephone.error}
          label="Teléfono residencial"
          value={telephone.value}
          name="telephone"
          placeholder="(809) 555-4444"
          onChange={e => handlePhoneChange('telephone', telephone.value, e)}
          type="tel"
        />
      </Col>
      {isRequestForMinor.value === false && renderExtraInfo()}
    </Row>
  )
}
