import styled from 'styled-components'

export const FormFill = styled.label`
  padding: 0;
  margin: 0 0 24px 0;
  font-family: ${({ theme: { fonts } }) => fonts.tertiary};
  width: ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};

  ${({ theme: { media } }) => media.tablet`
    width: 100%;
  `}

  textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    margin: 0;
    width: 100%;
    height: 104px;
    padding: 12px 24px;
    line-height: normal;
    border-radius: 12px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    color: ${({ theme: { colors } }) => colors.defaultTextColor};
    border: 2px solid ${({ theme: { colors } }) => colors.grayLight};

    &::placeholder {
      color: ${({ theme: { colors } }) => colors.gray};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme: { colors } }) => colors.grayDark};
    }

    &:hover {
      border: 2px solid ${({ theme: { colors } }) => colors.grayDark};
    }
  }

  ${({ validationMessage, theme: { colors } }) =>
    validationMessage &&
    `
      input {
        border-color: ${colors.required} !important;
      }

      .form-fill__validation {
        display: block;
        margin-top: 4px;
      }
    `}
`

export const FormFillTextArea = styled.textarea`
  resize: ${props => props.resize};
  width: 100%;
  height: 100%;
`
