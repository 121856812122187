import React from 'react'
import CrossIcon from '../../../../assets/cross.svg'
import HorizontalDivider from '../HorizontalDivider'
import { formatBytes, getExtensionFromMime } from '../../helpers'

import {
  FileName,
  FileText,
  TopFileElement,
  RemoveFileButton,
  FileElementContainer,
} from '..'

const FileElement = props => {
  const { index, file, onFileRemove } = props
  const { name, size, type } = file
  return (
    <FileElementContainer>
      <TopFileElement>
        <FileText>
          <FileName>{name}</FileName>
          <div>
            {getExtensionFromMime(type).toUpperCase()}
            {' - '}
            {formatBytes(size)}
          </div>
        </FileText>
        <RemoveFileButton
          src={CrossIcon}
          alt="Cross Icon"
          onClick={e => {
            onFileRemove(index)
            e.stopPropagation()
          }}
        />
      </TopFileElement>
      <HorizontalDivider />
    </FileElementContainer>
  )
}

export default FileElement
