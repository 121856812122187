import styled, { keyframes } from 'styled-components'
import color from 'color'
import check from '../../assets/check.svg'

const animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const HomePage = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grayLighter};
  margin: 0;
  padding: 0;
  animation: ${animation} 0.7s;
`

export const HomeContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
`
export const HomeContent = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  margin: 0 auto;
  max-width: 920px;
  padding: 46px 96px 96px;
  width: 100%;

  ${({ theme: { media } }) => media.tablet`
    padding: 46px 15px;
  `}
`

export const HomeBrandLogo = styled.div`
  height: auto;
  margin: 0 0 32px 40px;
  max-width: 252px;
  position: relative;

  ${({ theme: { media } }) => media.tablet`
    margin: 0 auto 32px;
    max-width: 200px;
  `}

  svg {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`

export const HomeTextContent = styled.div`
  background-color: ${({ theme: { colors } }) =>
    color(colors.grayLight).alpha(0.24).string()};
  border-radius: 24px;
  margin: 0 0 32px 0;
  padding: 32px 40px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme: { media } }) => media.tablet`
    padding: 24px;
  `}

  button {
    ${({ theme: { media } }) => media.tablet`
      width: 100%;
    `}
  }
`
export const HomeTextContentHeader = styled.div`
  position: relative;

  .home__text-content--icon & {
    padding-right: 128px;

    ${({ theme: { media } }) => media.tablet`
      padding-right: 68px;
    `}
  }
`

export const HomeContentIcon = styled.img`
  max-width: 158px;

  ${({ theme: { media } }) => media.tablet`
    max-width: 68px;
  `}

  .home__text-content--icon & {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

export const HomeContentText = styled.div``

export const DocumentList = styled.ul`
  padding: 0;
  margin-bottom: 44px;
  list-style-type: none;

  & > li {
    margin: 0 0 1rem 0;
    padding-left: 40px;
    background: url(${check}) no-repeat left center;
  }
`

export const BillProcessContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

export const BillStep = styled.div`
  width: 308px;
  display: flex;
  margin-top: 40px;
  text-align: center;
  align-items: center;
  flex-direction: column;

  & > img {
    width: 200px;
    margin-bottom: 16px;
  }

  & > strong {
    font-size: 20px;
    margin-bottom: 8px;
  }

  & > p {
    font-size: 16px;
    line-height: 24px;
  }
`
