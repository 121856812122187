import styled from 'styled-components'
import { colors } from '../../../../styles/theme'

export const NoOptionsContainer = styled.div`
  padding: 14px 24px 12px 24px;
  text-align: center;

  p {
    color: ${colors.blackOdd};
  }
`

export const ButtonContainer = styled.div`
  border-top: 1px solid ${colors.grayLight};
  padding-top: 12px;
`

export const Button = styled.button`
  border: 0;
  color: white;
  cursor: pointer;
  font-weight: bold;
  border-radius: 12px;
  line-height: 24px;
  text-transform: uppercase;
  background: ${colors.pink};
  padding: 8px 16px 8px 16px;
`
