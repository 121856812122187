import React, { useCallback, memo } from 'react'
import { Tabs, Tab } from '../../../components/Tabs'
import { BILL_PROCESS } from './tabs'
import { BillProcessContent, BillStep, HomeTextContent } from '../Style'

export const BillTabs = memo(() => {
  const renderStep = useCallback(
    step => (
      <BillStep key={step.title}>
        <img src={step.img} alt={step.message} />
        <strong>{step.title}</strong>
        <p>{step.message}</p>
      </BillStep>
    ),
    []
  )

  const renderTap = useCallback(
    tab => (
      <Tab title={tab.title} key={tab.title}>
        <BillProcessContent>{tab.steps.map(renderStep)}</BillProcessContent>
      </Tab>
    ),
    [renderStep]
  )

  return (
    <HomeTextContent style={{ textAlign: 'center' }}>
      <h2>{BILL_PROCESS.title}</h2>
      <Tabs isResponsiveRow>{BILL_PROCESS.tabs.map(renderTap)}</Tabs>
    </HomeTextContent>
  )
})
