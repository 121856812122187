import { ERROR } from './constants'

export const covidTestDoneErrorValue = (fieldValue, triedToContinue) => {
  if (triedToContinue && fieldValue === '') {
    return ERROR.EMPTY.REQUIRED_INFO
  }
  return undefined
}

export const lastTestResultErrorValue = (fieldValue, triedToContinue) => {
  if (triedToContinue && fieldValue === '') {
    return ERROR.EMPTY.REQUIRED_INFO
  }
  return undefined
}

const composedCheck = condition => (fieldValue, triedToContinue) => {
  if (triedToContinue && condition(fieldValue)) {
    return ERROR.EMPTY.REQUIRED_INFO
  }

  return undefined
}

const isEmpty = value => value === ''
const isZero = value => value === 0
const unselected = value => isEmpty(value) || isZero(value)

export const billFromErrorValue = composedCheck(isEmpty)
