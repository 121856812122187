import React from 'react'
import {
  FormFill as Form,
  FormFillLabel,
  FormFillDescription,
  FormRequired,
  FormFillGroup,
  FormFillValidationMessage,
} from '../Style'

export const FormFill = ({
  styledLabel,
  label,
  description,
  children,
  isRequired,
  validation,
  descriptionComponent,
  disabled,
  ...props
}) => (
  <Form className="form-fill">
    {!styledLabel && (
      <FormFillLabel className="form-fill__label" disabled={disabled}>
        {label} {isRequired ? <FormRequired>*</FormRequired> : null}
      </FormFillLabel>
    )}
    {styledLabel && (
      <FormFillLabel
        style={!description && { marginBottom: 8 }}
        className="form-fill__label"
      >
        {styledLabel()}
      </FormFillLabel>
    )}
    {description ? (
      <FormFillDescription>{description}</FormFillDescription>
    ) : null}
    {descriptionComponent && descriptionComponent()}
    <FormFillGroup {...props}>{children}</FormFillGroup>
    <FormFillValidationMessage className="form-fill__validation">
      {validation}
    </FormFillValidationMessage>
  </Form>
)
