export const healthInsuranceModel = {
  idImage: {
    value: { s3FileName: '', data: [] },
    error: '',
    isRequired: true,
  },
  insuranceImage: {
    value: { s3FileName: '', data: [] },
    error: '',
    isRequired: true,
  },
  medicalIndicationImage: {
    value: { s3FileName: '', data: [] },
    error: '',
    isRequired: true,
  },
  isValid: true,
  isCompleted: false,
  triedToContinue: false,
}
