/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Row, Col } from 'reactstrap'

import { useForm } from '../../../../hooks/useForm'
import constants from '../../../../helpers/constants'

// Components
import { Radio } from '../../../../components/forms/Radio'
import { FormFill } from '../../../../components/forms/FormFill'
import { StepWrapper } from '../../../../components/StepWrapper'

const { TEST } = constants.FORMS

export const Test = ({ form }) => {
  const { data, setData } = useForm(form)

  const { covidTestDone, lastTestResult } = data

  return (
    <Row>
      <Col xs={12}>
        <StepWrapper title={TEST} />
      </Col>

      <Col xs={12}>
        <FormFill
          label="¿Se ha hecho la prueba del COVID-19 anteriormente?"
          isRequired
          validation={covidTestDone.error}
        >
          <Radio
            label="Sí"
            name="test"
            id="siTest"
            value={covidTestDone.value}
            checked={covidTestDone.value}
            onChange={() => setData({ covidTestDone: true })}
          />
          <Radio
            label="No"
            name="test"
            id="noTest"
            value={covidTestDone.value}
            checked={covidTestDone.value === false}
            onChange={() => setData({ covidTestDone: false })}
          />
        </FormFill>
      </Col>
      {covidTestDone.value && (
        <Col xs={12}>
          <FormFill
            label="¿Cuál fue el resultado de su última prueba?"
            isRequired
            validation={lastTestResult.error}
          >
            <Radio
              label="Positivo"
              name="lastTest"
              id="positivo"
              value={lastTestResult.value}
              checked={lastTestResult.value}
              onChange={() => setData({ lastTestResult: true })}
            />
            <Radio
              label="Negativo"
              name="lastTest"
              id="negativo"
              value={lastTestResult.value}
              checked={lastTestResult.value === false}
              onChange={() => setData({ lastTestResult: false })}
            />
          </FormFill>
        </Col>
      )}
    </Row>
  )
}
