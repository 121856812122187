import React from 'react'

export const COVID_19 = {
  QUESTION1: 'Bienvenido al formulario para solicitar la prueba del COVID-19',
  DESCRIPTION: {
    FIRST: `A través de este acceso podrás completar el proceso requerido para `,
    SECOND: `solicitar la prueba PCR del COVID-19. `,
    THIRD: `La Resolución 00237-2021 de la SISALRIL modifica el proceso de cobertura de la prueba RT PCR SARS-COV-2.`,
    FOURTH: `A partir del 23 de abril, el Paciente debe presentar el resultado de una prueba de antígeno máximo 72 horas a partir de su toma de muestra. Así mismo, establece que solo podrá beneficiarse de esta cobertura una vez al año. La aprobación de cobertura de esta prueba queda sujeta a la autorización de su ARS y el laboratorio no tiene injerencia en la misma.`,
    FIFTH: `Para más información consulte la `,
    LINK: `Resolución Administrativa No. 00237-2021`,
    DOT: '.',
  },
}

export const ONLINE_TOOL = {
  TITLE: 'Requisitos',
  DESCRIPTION: {
    FIRST: `Es `,
    SECOND: `imprescindible `,
    THIRD: `tener a mano los siguientes documentos: `,
    FOURTH: `Es  imprescindible tener a mano los siguientes documentos:`,
  },
  DOCUMENTS: {
    FIRST: 'Cédula o Pasaporte.',
    SECOND:
      'Indicación que sólo contenga la prueba del COVID-19 vigente, firmada y sellada por su médico (si es asegurado).',
    THIRD: 'Carnet del seguro (si es asegurado).',
  },
  URL: 'http://www.sisalril.gov.do/phocadownload/Resoluciones/Res_Adm_Generales/2021/Res_Adm.No_00237_2021.pdf',
}

export const GET_STARTED = 'Comenzar'

export const PCR_MODAL = {
  TITLE: 'Estimado paciente',
  DESCRIPTION: {
    FIRST: `Nuestra asignación diaria de atención a solicitudes de pruebas para detección del `,
    SECOND: `COVID-19 por PCR se ha completado; cada día a partir de las 6:00 am habilitamos nuevos cupos.`,
    THIRD: `Recuerde que tenemos disponibilidad de `,
    FOURTH: `pruebas de Anticuerpos para COVID-19`,
    FIFTH: `, de `,
    SIXTH: `Panel Respiratorio con COVID-19 `,
    SEVENTH: `y de `,
    EIGHTH: `Antígenos para COVID-19`,
    NINETH: `, las cuales pueden ser solicitadas a través de AmaditaApp y en nuestras Sucursales a nivel nacional.`,
  },
  BUTTON_LABEL: 'De acuerdo',
}

export const PCR_NOTICE_MODAL = {
  TITLE: 'Estimado paciente',
  DESCRIPTION: {
    FORM_FINISHED: `Ya que ha completado`,
    FORM_NOT_FINISHED: `Luego de completar`,
    FIRST: ` exitosamente este formulario, debe proceder por una de las siguientes vías: `,
    THANKS: `Gracias por preferirnos.`,
    DIGITAL_BILL: () => (
      <>
        <strong>Facturación digital: </strong>
        Presione el botón de
        <strong> facturar en la APP </strong>y siga el proceso.
      </>
    ),
    PRESENTIAL_BILL: () => (
      <>
        <strong>Facturación presencial: Dirigirse a la sucursal </strong>
        de su preferencia.
      </>
    ),
    WHATSAPP_BILL: () => (
      <>
        <strong>Facturación telefónica whatsapp: </strong>
        Escribir o llamar al teléfono <strong>809-682-5414</strong>.
      </>
    ),
  },
  BUTTON_LABEL: 'De acuerdo',
}

export const NO_TESTS_AVAILABLE_MODAL = {
  TITLE: 'Estimado paciente',
  DESCRIPTION: {
    FIRST: `Nuestra asignación diaria para atender solicitudes de pruebas para detección del `,
    SECOND: `COVID-19 por PCR se ha agotado; `,
    THIRD: `estaremos recibiendo nuevas solicitudes a la mayor brevedad posible.`,
    FOURTH: `Recuerde que tenemos disponibilidad de `,
    FIFTH: `pruebas de Anticuerpos para COVID-19 `,
    SIXTH: `y `,
    SEVENTH: `Panel Respiratorio con COVID-19`,
    EIGHTH: `, las cuales pueden ser solicitadas a través de AmaditaApp y en nuestras Sucursales a nivel nacional.`,
  },
  BUTTON_LABEL: `De acuerdo`,
}
