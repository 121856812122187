import { useCallback, useMemo, useRef } from 'react'
import qs from 'query-string'

/**
 * Amadita app hook
 *
 * @summary Use this hook to find out if the covid form is being used from
 * the Amadita application in a web view or from the browser.
 */
export const useAmaditaApp = () => {
  const { ReactNativeWebView: RN } = useRef(window).current
  const isRN = useRef(!!(RN && typeof RN.postMessage === 'function')).current

  /**
   * @summary Validate if the webview has submitted the covid-form ID
   */
  const isAmaditaApp = useMemo(() => {
    const query = qs.parse(window.location.search)
    const version = query && query['version']

    return !!(version && isRN)
  }, [isRN])

  /**
   * @throws Use this only to send DOM events to the amadita app,
   * not to send sensitive patient information.
   */
  const sendMessageToApp = useCallback(
    object => {
      if (typeof object !== 'object')
        throw new Error('Oops!, the parameter must be an object')

      if (isAmaditaApp) RN.postMessage(JSON.stringify(object))
    },
    [isAmaditaApp, RN]
  )

  return {
    isAmaditaApp,
    sendMessageToApp,
  }
}
