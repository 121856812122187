import React from 'react'
import { MODAL } from './constants'
import { Description, CaptchaContainer } from './Style'
import { SimpleModal } from '../../'
import ReCAPTCHA from 'react-google-recaptcha'

export const CaptchaModal = ({ isOpen, onClose = () => {}, onFinish }) => {
  const renderCaptchaModalBody = () => (
    <>
      <Description>{MODAL.TEXTS.BODY.FIRST}</Description>
      <CaptchaContainer>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          onChange={onFinish}
        />
      </CaptchaContainer>
    </>
  )

  return (
    <SimpleModal
      isOpen={isOpen}
      body={renderCaptchaModalBody()}
      title={MODAL.TEXTS.TITLE}
      onCloseModal={onClose}
    />
  )
}
