import mixpanel from 'mixpanel-browser'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

const actions = {
  track: (name, props) => {
    mixpanel.track(name, props)
  },
}

export const Mixpanel = actions
