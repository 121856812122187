export const MODAL = {
  TEXTS: {
    BODY: {
      FIRST: 'Recuerde que ',
      SECOND: 'para completar su solicitud ',
      THIRD: 'debe enviarnos las fotos de sus documentos. ',
      FOURTH: 'Le ',
      FIFTH: 'hemos enviado un correo ',
      SIXTH:
        'con su código de solicitud y lo pasos a seguir para enviarlas en caso de que no las haya podido enviar directamente desde el formulario.',
    },
    TITLE: 'Estimado paciente',
    BUTTON_LABEL: 'Entendido',
  },
}
