export default {
  APP_NAME: 'Amadita - COVID-19 (Coronavirus)',
  BEFORE_STARTING: 'Antes de comenzar',
  COVID: 'COVID-19 (Coronavirus)',
  ONLINE_TOOL: 'Herramienta en línea',
  LAB_URL: 'https://amadita.com/',
  FORMS: {
    BEFORE_START: 'Antes de empezar',
    GET_STARTED: 'Comencemos',
    ADDRESS: '¿Cuál es su dirección?',
    SYMPTOM: 'Síntomas',
    SPECIAL_CONDITIONS: 'Condiciones especiales',
    LAST_WEEKS: 'En las últimas 2 semanas...',
    TRAVEL: 'Entrada o salida al país',
    MEDICAL_REFERRAL: 'Referimiento médico',
    TEST: 'Prueba COVID-19',
    HEALTH_INSURANCE: 'Información requerida',
  },
  BUTTONS: {
    PREV: 'Volver',
    NEXT: 'Continuar',
    SUBMIT: 'Enviar',
  },
  GET_STARTED: {
    MINOR1: 'Colocar los datos de ',
    MINOR2: 'uno de los padres o tutor.',
  },
  MODAL: {
    ERROR: {
      TITLE: 'Error inesperado',
      BODY: 'Los servidores están temporalmente fuera de servicio, por favor intenta más tarde',
      BUTTON_LABEL: 'Ok, volver',
    },
    PCR_LIMIT: {
      BODY: {
        FIRST: 'Solo tenemos disponibilidad para la Prueba Rápida.',
        SECOND:
          'Mientras llenabas el formulario se agotaron los cupos para las pruebas COVID-19 por PCR. Mañana ',
        THIRD: 'a partir de las 6:00am ',
        FOURTH: 'habilitaremos más.',
        QUESTION: '¿Deseas cambiar el tipo de prueba?',
      },
      TITLE: '¡Lo sentimos!',
      RIGHT_BUTTON_LABEL: 'No, volver',
      LEFT_BUTTON_LABEL: 'Sí, cambiar',
    },
  },
  ERROR: 'ERROR',
  CEDULA: 'CEDULA',
  PASAPORTE: 'PASAPORTE',
}

export const INVALID_ELEMENTS_CLASS = 'form-fill__validation'

export const UPLOADER = {
  FILELIMIT: 1,
}

export const APP_ID = String(process.env.REACT_APP_ID || 4)

export const MODAL_ERRORS_CODE = {
  VALIDATION_DOCUMENTS: 1,
  PCR_AVAILABILITY: 2,
  GET_DOCTORS: 3,
  GET_PROVINCES: 4,
  GET_NATIONALITIES: 5,
  GET_HEALTH_INSURANCES: 6,
  SUBBMITTING_FORM: 7,
  UPLOAD_HEALTH_INSURANCES_IMAGES: 8,
}
