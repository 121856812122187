import React from 'react'

import { PCR_MODAL } from '../constants'
import { SimpleModal } from '../../../components/Modal'
import { Description } from './Style'

export const PCRAvailabilityModal = ({ onClick, isOpen, onCloseModal }) => {
  const getPCRModalBody = () => (
    <>
      <Description>
        {PCR_MODAL.DESCRIPTION.FIRST}
        <strong>{PCR_MODAL.DESCRIPTION.SECOND}</strong>
      </Description>
      <Description>
        {PCR_MODAL.DESCRIPTION.THIRD}
        <strong>{PCR_MODAL.DESCRIPTION.FOURTH}</strong>
        {PCR_MODAL.DESCRIPTION.FIFTH}
        <strong>{PCR_MODAL.DESCRIPTION.SIXTH}</strong>
        {PCR_MODAL.DESCRIPTION.SEVENTH}
        <strong>{PCR_MODAL.DESCRIPTION.EIGHTH}</strong>
        {PCR_MODAL.DESCRIPTION.NINETH}
      </Description>
    </>
  )

  return (
    <SimpleModal
      title={PCR_MODAL.TITLE}
      body={getPCRModalBody()}
      isOpen={isOpen}
      onClick={onClick}
      buttonLabel={PCR_MODAL.BUTTON_LABEL}
      onCloseModal={onCloseModal}
    />
  )
}
