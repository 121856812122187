import styled from 'styled-components'

export const FormFill = styled.div`
  font-family: ${({ theme: { fonts } }) => fonts.tertiary}, FontAwesome;
  margin: 0 0 24px 0;
  padding: 0;
  width: 100%;

  &.required,
  &.required:hover {
    .form-fill-radio-button,
    .form-fill-check-button {
      .form-fill-check-bg {
        border-color: ${({ theme: { colors } }) => colors.required};
      }
    }

    .form-fill__validation {
      display: block;
      margin-top: 4px;
    }

    .react-select__control {
      border-color: ${({ theme: { colors } }) => colors.required};
    }
  }
`
export const FormFillLabel = styled.span`
  color: ${({ disabled }) => disabled && '#7a7a7a'};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 4px;
  display: block;
`

export const FormFillDescription = styled.p`
  font-family: Roboto;
  font-size: 16px;
`

export const FormRequired = styled.span`
  color: ${({ theme: { colors } }) => colors.required};
`

export const FormAccent = styled.span`
  color: ${({ theme: { colors } }) => colors.primary};
`

export const FormFillGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -16px;
`
export const FormFillValidationMessage = styled.span`
  color: ${({ theme: { colors } }) => colors.required};
`
