import React, { memo, useContext } from 'react'
import { FormContext } from '../../screens/Form/Context'
import { Container, ButtonGroup } from './Style'
import { StepOf } from '../StepOf'
import { Button } from '../Button'
import constants from '../../helpers/constants'
import { NUMBER_TO_STEP_NAME } from '../../screens/Form/models'
import { DataContext } from '../../context'

const { PREV, NEXT, SUBMIT } = constants.BUTTONS
export const FormFooter = memo(({ handleNextClick, submitForm }) => {
  const {
    formStatus: { currentStep, steps, isComplete },
    prevStep,
  } = useContext(FormContext)
  const { formData } = useContext(DataContext)

  const isSectionCompleted =
    currentStep != null &&
    formData.steps[NUMBER_TO_STEP_NAME[currentStep]].isCompleted
  const isSectionValid =
    currentStep != null &&
    formData.steps[NUMBER_TO_STEP_NAME[currentStep]].isValid

  return (
    <Container>
      <StepOf currentStep={currentStep} steps={steps} />
      <ButtonGroup>
        {currentStep > 1 && (
          <Button outline onClick={prevStep}>
            {PREV}
          </Button>
        )}
        <Button
          forceEnabled
          primary
          onClick={
            isComplete && isSectionCompleted && isSectionValid
              ? submitForm
              : handleNextClick
          }
          type={
            isComplete && isSectionCompleted && isSectionValid ? 'submit' : null
          }
          disabled={!isSectionCompleted || !isSectionValid}
        >
          {isComplete ? SUBMIT : NEXT}
        </Button>
      </ButtonGroup>
    </Container>
  )
})
