import React from 'react'
import { Description } from './Style'
import { MODAL } from './constants'
import { SimpleModal } from '../../'

const getUnsupportedFileTypeModalBody = () => (
  <Description>{MODAL.UNSUPPORTED.BODY}</Description>
)

export const UnsupportedFileTypeModal = ({
  isOpen,
  onClick,
  onClose = () => {},
}) => (
  <SimpleModal
    isOpen={isOpen}
    body={getUnsupportedFileTypeModalBody()}
    title={MODAL.UNSUPPORTED.TITLE}
    buttonLabel={MODAL.UNSUPPORTED.BUTTON_LABEL}
    onCloseModal={onClose}
    onClick={onClick}
  />
)
