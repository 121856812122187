import React, { useState, useEffect } from 'react'
import {
  SelectElement,
  NativeSelectElement,
  NativeSelectContainer,
  ErrorMessage,
  DownArrowContainer,
} from './Style'
import {
  FormFill,
  FormFillLabel,
  FormRequired,
  FormFillValidationMessage,
} from '../Style'
import { components } from 'react-select'
import DownArrowIcon from './assets/down-arrow'

const mobileDeviceSize = 768
const isMediumSizeDevice = window.innerWidth <= mobileDeviceSize

const IndicatorsContainer = props => {
  return (
    <div
      style={{
        position: 'absolute',
        right: '16px',
        top: '50%',
        transform: 'translate3d(0, -50%, 0)',
        zIndex: '1',
      }}
    >
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

const NativeSelectOptions = ({ options, placeholder, selectedOption }) => (
  <>
    <option value={0} selected={selectedOption === 0}>
      {placeholder}
    </option>
    {options &&
      options.map(option => {
        const value = JSON.stringify({ ...option })
        return (
          <option
            value={value}
            selected={selectedOption === option.id}
            key={option.id}
          >
            {option.label}
          </option>
        )
      })}
  </>
)

const controlStyles = isFocused => ({
  backgroundColor: 'white',
  appearance: 'none',
  lineHeight: '1.75',
  padding: '0 48px 0 12px',
  position: 'relative',
  zIndex: '0',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: isFocused ? '#4C5A64' : '#E1E2E2',
  boxSizing: 'border-box',
  borderRadius: '12px',
  width: '100%',
  color: '#222222',
  transition: 'all 0.3s ease-in-out',
  margin: '0',
  height: '56px',
  boxShadow: 'none',

  ':hover': {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#4C5A64',
  },
})

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    ...controlStyles(isFocused),
  }),
  menu: styles => ({ ...styles, zIndex: '10' }),
  option: (styles, { isSelected }) => ({
    ...styles,
    color: isSelected ? 'white !important' : '#222222',
    backgroundColor: isSelected ? '#ED2B74 !important' : null,
    ':active': {
      ...styles[':active'],
      backgroundColor: '#E1E2E2',
      color: '#222222',
    },
    ':hover': {
      ...styles[':hover'],
      backgroundColor: '#E1E2E2',
      color: '#222222',
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles, color: '#A7ABAE' }),
  singleValue: (styles, { data }) => ({ ...styles }),
}

export const Select = React.forwardRef(
  (
    {
      options,
      label,
      placeholder,
      isRequired,
      validationMessage,
      noOptionsMessage,
      onChange,
      searchable,
      additionalComponents,
      forceDesktopComponent,
      ...props
    },
    ref
  ) => {
    const isNumber = value => typeof value === 'number'
    const noValue = value => value == null || isNumber(value)
    const valueId = noValue(props.value) ? 0 : props.value.id

    const [isFocused, setFocus] = useState(false)
    const [selectedId, setSelectedId] = useState(valueId)

    useEffect(() => {
      setSelectedId(valueId)
    }, [valueId])

    const handleNativeSelectOnChange = e => {
      const { value } = e.target
      const selectedOption = (value !== '0' && { ...JSON.parse(value) }) || 0
      setSelectedId((selectedOption && selectedOption.id) || 0)
      onChange(selectedOption)
    }

    const nativeSelectStyles = {
      ...colourStyles.control({}, { isFocused: false }),
      paddingLeft: '24px',
      borderColor: validationMessage ? '#B00020' : '#E1E2E2',
      color: selectedId === 0 ? '#A7ABAE' : '#000000',
    }

    return (
      <FormFill
        className="form-fill-select"
        validationMessage={validationMessage}
      >
        <FormFillLabel>
          {label} {isRequired ? <FormRequired>*</FormRequired> : null}
        </FormFillLabel>
        {isMediumSizeDevice && !forceDesktopComponent ? (
          <NativeSelectContainer>
            <NativeSelectElement
              style={nativeSelectStyles}
              onChange={handleNativeSelectOnChange}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            >
              <NativeSelectOptions
                options={options}
                placeholder={placeholder}
                selectedOption={valueId}
              />
            </NativeSelectElement>
            <DownArrowContainer>
              <DownArrowIcon
                fill={isFocused ? '#4C5A64' : '#E1E2E2'}
                style={{ transition: 'all 0.3s ease-in-out' }}
              />
            </DownArrowContainer>
          </NativeSelectContainer>
        ) : (
          <SelectElement
            classNamePrefix="react-select"
            options={options}
            components={{ IndicatorsContainer, ...additionalComponents }}
            placeholder={placeholder}
            {...props}
            onChange={onChange}
            ref={ref}
            styles={{
              ...(validationMessage
                ? {
                    ...colourStyles,
                    control: styles => ({
                      ...styles,
                      ...controlStyles(false),
                      borderColor: '#B00020',
                    }),
                  }
                : colourStyles),
              dropdownIndicator: (provided, state) => {
                return {
                  ...provided,
                  transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
                }
              },
              noOptionsMessage: styles => ({ ...styles, padding: 0 }),
            }}
            isSearchable={searchable}
            noOptionsMessage={() => noOptionsMessage}
          />
        )}
        <ErrorMessage validationMessage={validationMessage}>
          <FormFillValidationMessage className="form-fill__validation">
            {validationMessage}
          </FormFillValidationMessage>
        </ErrorMessage>
      </FormFill>
    )
  }
)
