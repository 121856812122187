import { amaditaApi } from './amaditaCovidApi'
import constants from '../helpers/constants'
import { formMapper, notificationMapper } from '../data/mappers/formMapper'

export const idVerification = async id => {
  try {
    const response = await amaditaApi.verifyDocument(id)
    return response.data.document
  } catch (e) {
    return constants.ERROR
  }
}

export const formSubmission = async data => {
  try {
    const response = await amaditaApi.sendFormCovid(formMapper(data))
    return response
  } catch (e) {
    return constants.ERROR
  }
}

export const emailSubscription = async data => {
  try {
    const response = await amaditaApi.subscribeEmail(notificationMapper(data))
    return response
  } catch (e) {
    return constants.ERROR
  }
}
