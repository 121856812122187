import styled from 'styled-components'

export const FormFillCheckButton = styled.label`
  align-items: center;
  box-sizing: border-box;
  color: ${({ theme: { colors } }) => colors.defaultTextColor};
  cursor: pointer;
  display: flex;
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 16px;
  padding: 14px 24px;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: auto;

  &::placeholder {
    color: ${({ theme: { colors } }) => colors.secondary};
  }

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover .form-fill-check,
  &:hover .form-fill-check-bg {
    border-color: ${({ theme: { colors } }) => colors.grayDark};
  }

  input {
    display: none;
    height: 0;
    width: 0;

    &[type='checkbox']:checked ~ .form-fill-check-icon .form-fill-check-active {
      opacity: 1;
    }

    &[type='checkbox']:checked ~ .form-fill-check-bg {
      border-color: ${({ theme: { colors } }) => colors.primary};
    }
  }
`

export const FormFillCheckIcon = styled.div`
  display: block;
  height: 24px;
  margin-right: 16px;
  position: relative;
  width: 24px;
`

export const FormFillCheck = styled.div`
  border-radius: 50%;
  border: 2px solid ${({ theme: { colors } }) => colors.grayLight};
  display: block;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: 24px;
  z-index: 1;
`

export const FormFillCheckActive = styled.div`
  display: flex;
  height: 24px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: 24px;
  z-index: 2;
`

export const FormFillCheckBoxLabel = styled.span``

export const FormFillCheckBg = styled.div`
  border-radius: 12px;
  border: solid 2px ${({ theme: { colors } }) => colors.grayLight};
  content: '';
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 1;
`
