export const isFormValid = (formData, steps) => {
  let isValid = true
  const hasInsurance = steps.beforeStart['hasInsurance'].value
  for (const [key] of Object.entries(formData)) {
    if (key === 'isValid' || key === 'isCompleted') continue
    if (
      !hasInsurance &&
      (key === 'insuranceImage' || key === 'medicalIndicationImage')
    )
      continue

    if (formData[key].error) {
      isValid = false
    }
  }

  return isValid
}
