import React, { memo } from 'react'
import { ButtonElement, Spinner, SpinnerContainer } from './Style'

export const Button = memo(props => (
  <ButtonElement
    {...props}
    id={props.id}
    style={props.style}
    outline={props.outline}
    onClick={props.onClick}
    primary={props.primary}
    maxContent={props.maxContent}
    isLoading={props.isLoading}
    className={props.className}
    type={props.type || 'button'}
    disabled={(props.disabled && !props.forceEnabled) || props.isLoading}
    forceEnabled={props.disabled && props.forceEnabled}
  >
    {props.isLoading ? (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    ) : (
      props.children
    )}
  </ButtonElement>
))
