import React from 'react'
import { Modal } from '../Modal'
import { Button } from '../../Button'
import { TextContent } from './Style'
import './modalStyle.css'

export const SimpleModal = ({
  body,
  title,
  isOpen,
  onClick,
  buttonLabel,
  code,
  onCloseModal = () => {},
}) => (
  <Modal
    open={isOpen}
    showCloseIcon={false}
    onClose={onCloseModal}
    classNames={{
      overlay: 'simple-modal',
    }}
    animationDuration={300}
    blockScroll={false}
  >
    <h2>{title}</h2>
    <TextContent style={{ paddingBottom: 8 }}>{body}</TextContent>
    {code && (
      <div className="code">
        Código de error: <small>#{code}</small>
      </div>
    )}
    {onClick && (
      <Button className="w-100" primary onClick={onClick} id="modalButton">
        {buttonLabel}
      </Button>
    )}
  </Modal>
)
