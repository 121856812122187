import { ERROR } from './constants'
import {
  isNameValid,
  isEmailValid,
  isCompletelyNumber,
  emptyIfNullOrUndefined,
} from '../../../../helpers/strings'

const MINIMUM_SSN_LENGTH = 8
const MAXIMUM_SSN_LENGTH = 10
const FORMATTED_PHONE_LENGTH = 14

export const nameErrorValue = (fieldValue, triedToContinue) => {
  const nameText = emptyIfNullOrUndefined(fieldValue).trim()
  if (triedToContinue) {
    if (nameText === '') {
      return ERROR.EMPTY.REQUIRED_INFO
    }

    if (nameText !== '' && !isNameValid(nameText)) {
      return ERROR.INVALID.NAME
    }
  }
  return undefined
}

export const lastNameErrorValue = (fieldValue, triedToContinue) => {
  const lastNameText = emptyIfNullOrUndefined(fieldValue).trim()

  if (triedToContinue) {
    if (lastNameText === '') {
      return ERROR.EMPTY.REQUIRED_INFO
    }

    if (lastNameText !== '' && !isNameValid(lastNameText)) {
      return ERROR.INVALID.LASTNAME
    }
  }
  return undefined
}

export const secondLastNameErrorValue = (fieldValue, triedToContinue) => {
  const lastNameText = emptyIfNullOrUndefined(fieldValue).trim()

  if (lastNameText !== '' && !isNameValid(lastNameText) && triedToContinue) {
    return ERROR.INVALID.LASTNAME
  }

  return undefined
}

export const nationalityErrorValue = (fieldValue, triedToContinue) => {
  if (triedToContinue) {
    if (!fieldValue.value) {
      return ERROR.EMPTY.REQUIRED_INFO
    }
  }
  return undefined
}

export const genderErrorValue = (fieldValue, triedToContinue) => {
  const genderText = emptyIfNullOrUndefined(fieldValue).trim()
  if (triedToContinue && genderText === '') {
    return ERROR.EMPTY.REQUIRED_INFO
  }
  return undefined
}

export const pregnantErrorValue = (fieldValue, triedToContinue) => {
  if (triedToContinue && fieldValue === '') {
    return ERROR.EMPTY.REQUIRED_INFO
  }
  return undefined
}

export const birthDateErrorValue = (fieldValue, triedToContinue) => {
  if (triedToContinue && fieldValue === '') {
    return ERROR.EMPTY.REQUIRED_INFO
  }

  const dateValue =
    fieldValue && typeof fieldValue === 'string'
      ? new Date(fieldValue)
      : fieldValue
  if (fieldValue && !(dateValue <= new Date()) && triedToContinue) {
    return ERROR.INVALID.FUTURE_DATE
  }
  return undefined
}

export const emailErrorValue = (fieldValue, triedToContinue) => {
  const emailText = emptyIfNullOrUndefined(fieldValue).trim()
  const isEmpty = emailText === ''
  const isInvalid = !isEmailValid(fieldValue)

  if (triedToContinue) {
    if (isEmpty) {
      return ERROR.EMPTY.REQUIRED_INFO
    } else {
      if (isInvalid) {
        return ERROR.INVALID.EMAIL
      }
    }
  }

  return undefined
}

export const phoneErrorValue = (fieldValue, triedToContinue) => {
  const phoneText = emptyIfNullOrUndefined(fieldValue).trim()
  const isEmpty = phoneText === ''
  const isInvalid = phoneText.length !== FORMATTED_PHONE_LENGTH

  if (triedToContinue) {
    if (isEmpty) {
      return ERROR.EMPTY.REQUIRED_INFO
    } else {
      if (isInvalid) {
        return ERROR.INVALID.PHONE
      }
    }
  }

  return undefined
}

export const telephoneErrorValue = (fieldValue, triedToContinue) => {
  const phoneText = emptyIfNullOrUndefined(fieldValue).trim()
  const isEmpty = phoneText === ''
  const isInvalid = phoneText.length !== FORMATTED_PHONE_LENGTH

  if (triedToContinue && !isEmpty && isInvalid) {
    return ERROR.INVALID.TELEPHONE
  }

  return undefined
}
