import styled, { keyframes } from 'styled-components'

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const ButtonElement = styled.button.attrs(props => ({
  forceEnabled: props.forceEnabled,
}))`
  padding: 12px 32px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${({ maxContent }) => (maxContent ? 'max-content' : '100%')};

  &:active,
  &:focus,
  &:hover,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    border-color: transparent;
    box-shadow: 0 0 0 0 transparent;
    outline: none;
    text-decoration: none;
  }

  &::after {
    display: none;
  }

  ${({ primary, theme: { colors } }) =>
    primary &&
    `
      background-color: ${colors.primary};
      color: ${colors.white};
      border: 1px solid ${colors.primary};
      outline: none;
      box-shadow: 0 0 0 0 transparent;

      &:active,
      &:focus,
      &:hover,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active {
        background-color: ${colors.primary};
        color: ${colors.white};
        border: 1px solid ${colors.primary};
        outline: none;
        box-shadow: 0 0 0 0 transparent;
      }

    `}

  ${({ disabled, theme: { colors } }) =>
    disabled &&
    `
    &,
    &:disabled {
      user-select: none;
      pointer-events: none;
      cursor: not-allowed;
      background-color: ${colors.grayLight};
      color: ${colors.secondary};
      border-color: transparent;
    }
  `}

  ${({ forceEnabled, theme: { colors } }) =>
    forceEnabled &&
    `
    &:hover,
    &:not(:hover),
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      user-select: none;
      background-color: ${colors.grayLight};
      color: ${colors.secondary};
      border-color: transparent;
    }
  `}

  ${({ outline, theme: { colors } }) =>
    outline &&
    `
      background: ${colors.secondary};
      border: solid 2px ${colors.grayLight};
      color: ${colors.primary};

      &:active,
      &:focus,
      &:hover,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active {
        background-color: ${colors.secondary};
        color: ${colors.primary};
        border: solid 2px ${colors.grayLight};
      }

      &:disabled {
        background-color: ${colors.secondary};
        color: ${colors.grayLight};
        border: solid 2px ${colors.grayLight};
      }
    `}
`

export const Spinner = styled.div`
  width: 32px;
  height: 32px;
  border: 4px solid ${({ theme: { colors } }) => colors.grayLight};
  border-top: 4px solid ${({ theme: { colors } }) => colors.white};
  border-radius: 50%;
  animation: ${loading} 0.9s linear infinite;
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`
