import React from 'react'
import { ThemeProvider } from 'styled-components'

export const colors = {
  black: '#000000',
  blackOdd: '#222222',
  white: '#ffffff',
  pink: '#ED2B74',
  gray: '#A7ABAE',
  grayLighter: '#F5F5F5',
  grayLight: '#E1E2E2',
  grayDark: '#4C5A64',
  required: '#D1153B',
  success: '#74A90A',
  warning: '#F5A623',
}

export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '991.98px',
  xl: '1200px',
}

export const fonts = {
  inter: "'Inter', sans-serif",
  openSans: "'Open Sans', Helvetica, sans-serif",
  roboto: "'Roboto', sans-serif",
}

const themeOpts = {
  colors: {
    ...colors,
    primary: colors.pink,
    secondary: colors.white,
    defaultTextColor: colors.blackOdd,
  },
  opacity: {
    default: 1,
    disabled: 0.7,
  },
  fonts: {
    ...fonts,
    primary: fonts.inter,
    secondary: fonts.openSans,
    tertiary: fonts.roboto,
    default: fonts.openSans,
  },
  breakpoints,
}

const up = ({ size, styles }) =>
  `@media only screen and (min-width: ${size}) {
    ${styles}
  }`

const down = ({ size, styles }) =>
  `@media only screen and (max-width: ${size}) {
    ${styles}
  }`

const media = {
  up,
  down,
  mobile: styles =>
    down({
      size: breakpoints.sm,
      styles,
    }),

  tablet: styles =>
    down({
      size: breakpoints.lg,
      styles,
    }),

  desktop: styles =>
    up({
      size: breakpoints.lg,
      styles,
    }),

  desktopFull: styles =>
    up({
      size: breakpoints.xl,
      styles,
    }),
}

export const Theme = ({ children }) => (
  <ThemeProvider theme={{ ...themeOpts, media }}>{children}</ThemeProvider>
)
