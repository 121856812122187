import styled from 'styled-components'

export const Title = styled.h2``

export const Require = styled.p`
  span {
    color: ${({ theme: { colors } }) => colors.required};
    font-weight: bold;
  }
`

export const Container = styled.div`
  margin-bottom: 16px;
`
