/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'

// Components
import { Row, Col } from 'reactstrap'
import { SimpleModal } from '../Modal'
import { StepWrapper } from '../StepWrapper'
import { InvisibleField } from '../InvisibleField'
import { Question } from '../Question'
import { QuestionType } from '../../helpers/question'
import {
  MaxImagesModal,
  UnsupportedFileTypeModal,
} from '../Modal/implementations'
import { MODAL_ERRORS_CODE } from '../../helpers/constants'

export const Step = props => {
  const { title, questions, stepConstants, visibleModal, handleModalClose } =
    props

  const [isMaxImagesModalOpen, setMaxImagesModalOpen] = useState(false)
  const toggleMaxImagesModal = () =>
    setMaxImagesModalOpen(!isMaxImagesModalOpen)
  const toggleFileTypeUnsupportedModal = () =>
    setFileTypeUnsupportedModalOpen(!isFileTypeUnsupportedModalOpen)
  const [isFileTypeUnsupportedModalOpen, setFileTypeUnsupportedModalOpen] =
    useState(false)

  return (
    <>
      <Row>
        <Col xs={12}>
          <StepWrapper title={title} />
        </Col>
        {questions.map((question, idx) => {
          const options = question

          if (question.type === QuestionType.UPLOADER) {
            options.onFileLimitReached = toggleMaxImagesModal
            options.onFileTypeUnsupported = toggleFileTypeUnsupportedModal
          }

          return <Question {...options} key={idx} />
        })}

        <>
          {visibleModal != null && visibleModal !== '' && (
            <>
              <SimpleModal
                isOpen={visibleModal === 'NOT_VALID'}
                body={stepConstants.MODAL.NOT_VALID.BODY}
                title={stepConstants.MODAL.NOT_VALID.TITLE}
                onClick={handleModalClose}
                onCloseModal={handleModalClose}
                buttonLabel={stepConstants.MODAL.BUTTON_LABEL}
              />
              <SimpleModal
                isOpen={visibleModal === 'HAS_REQUEST'}
                body={stepConstants.MODAL.HAS_REQUEST.BODY}
                title={stepConstants.MODAL.HAS_REQUEST.TITLE}
                onClick={handleModalClose}
                onCloseModal={handleModalClose}
                buttonLabel={stepConstants.MODAL.BUTTON_LABEL}
              />
              <SimpleModal
                code={MODAL_ERRORS_CODE.VALIDATION_DOCUMENTS}
                isOpen={visibleModal === 'ERROR'}
                body={stepConstants.MODAL.ERROR.BODY}
                title={stepConstants.MODAL.ERROR.TITLE}
                onClick={handleModalClose}
                onCloseModal={handleModalClose}
                buttonLabel={stepConstants.MODAL.BUTTON_LABEL}
              />
              <SimpleModal
                isOpen={visibleModal === 'UNDERAGED'}
                body={stepConstants.MODAL.UNDERAGED.BODY}
                title={stepConstants.MODAL.UNDERAGED.TITLE}
                onClick={handleModalClose}
                onCloseModal={handleModalClose}
                buttonLabel={stepConstants.MODAL.BUTTON_LABEL}
              />
            </>
          )}
        </>
        <>
          <MaxImagesModal
            isOpen={isMaxImagesModalOpen}
            onClose={toggleMaxImagesModal}
            onClick={toggleMaxImagesModal}
          />
          <UnsupportedFileTypeModal
            isOpen={isFileTypeUnsupportedModalOpen}
            onClose={toggleFileTypeUnsupportedModal}
            onClick={toggleFileTypeUnsupportedModal}
          />
        </>
        <InvisibleField />
      </Row>
    </>
  )
}
