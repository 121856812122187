import styled from 'styled-components'

export const Container = styled.header`
  position: relative;
  width: 100%;
  height: 64px;
`

export const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  background-color: ${({ theme: { colors } }) => colors.primary};
  position: fixed;
  top: 0;
  width: 100%;
  height: 64px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Brand = styled.div`
  padding: 0;
  margin: 0;
  width: 168px;
  height: 48px;
`
