export const beforeStartModel = {
  isRequestForMinor: { value: '', error: '', isRequired: true },
  documentType: { value: '', error: '', isRequired: true },
  id: { value: '', error: '', isRequired: true },
  passport: { value: '', error: '', isRequired: true },
  hasInsurance: { value: '', error: '', isRequired: true },
  isAntigenTested: { value: '', error: '', isRequired: true },
  antigenDate: { value: '', error: '', isRequired: true },
  antigenResult: { value: '', error: '', isRequired: true },
  antigenCondition: { value: '', error: '', isRequired: true },
  antigenEvidence: {
    value: { s3FileName: '', data: [] },
    error: '',
    isRequired: true,
  },
  triedToContinue: false,
}
