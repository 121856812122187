import styled from 'styled-components'

export const StepOfWrapper = styled.div`
  ${({ theme: { media } }) => media.tablet`
    background: white;
    left: 0;
    position: fixed;
    top: 64px;
    width: 100%;
    z-index: 14;
  `}
`

export const StepOfText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #4c5a64;
  margin-bottom: 8px;
  margin-top: 0;

  ${({ theme: { media } }) => media.tablet`
    display: none;
  `}
`

export const StepOfProgress = styled.div`
  background: rgba(225, 226, 226, 0.5);
  border-radius: 8px;
  width: 100%;
  height: 8px;
  min-width: 214px;

  ${({ theme: { media } }) => media.tablet`
    border-radius: 0;
    height: 4px;
  `}
`

export const StepOfProgressBar = styled.div`
  background: #4c5a64;
  border-radius: 4px;
  height: 8px;
  width: ${({ progress }) => `${progress}%`};
  transition: width 0.5s ease-in-out;

  ${({ theme: { media } }) => media.tablet`
    border-radius: 0;
    height: 4px;
  `}
`
