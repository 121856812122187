import React from 'react'
import { FormFillText, FormFill } from './Style'
import {
  FormFillLabel,
  FormRequired,
  FormFillValidationMessage,
} from '../Style'

export const Field = ({
  children,
  isRequired,
  label,
  name,
  type,
  onChange,
  onClick,
  widthAuto,
  validationMessage,
  value,
  disabled,
  ...props
}) => {
  return (
    <FormFill
      htmlFor={name}
      widthAuto={widthAuto}
      className="form-fill"
      isRequired
      validationMessage={validationMessage}
      disabled={disabled}
    >
      <FormFillLabel disabled={disabled}>
        {label} {isRequired ? <FormRequired>*</FormRequired> : null}
      </FormFillLabel>
      <FormFillText
        disabled={disabled}
        name={name}
        type={type ? type : 'text'}
        onChange={onChange}
        onClick={onClick}
        value={value}
        {...props}
      />
      {children}
      {validationMessage && (
        <FormFillValidationMessage className="form-fill__validation">
          {validationMessage}
        </FormFillValidationMessage>
      )}
    </FormFill>
  )
}
