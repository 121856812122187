import React from 'react'
import {
  FormFill as Form,
  FormFillLabel,
  FormFillDescription,
  FormRequired,
  FormFillGroup,
  FormFillValidationMessage,
} from '../Style'
import { Radio } from '../Radio'

export const RadioGroup = ({
  styledLabel,
  label,
  description,
  validation,
  isRequired,
  disabled,
  type,
  name,
  options,
  onChange,
  descriptionComponent,
  value,
  ...props
}) => (
  <Form className="form-fill">
    {!styledLabel && (
      <FormFillLabel className="form-fill__label" disabled={disabled}>
        {label} {isRequired ? <FormRequired>*</FormRequired> : null}
      </FormFillLabel>
    )}
    {styledLabel && (
      <FormFillLabel
        style={!description && { marginBottom: 8 }}
        className="form-fill__label"
      >
        {styledLabel()}
      </FormFillLabel>
    )}
    {description ? (
      <FormFillDescription>{description}</FormFillDescription>
    ) : null}
    {descriptionComponent && descriptionComponent()}
    <FormFillGroup {...props}>
      {options.map((params, idx) => (
        <Radio {...{ ...params, name, onChange, value }} key={idx} />
      ))}
    </FormFillGroup>
    <FormFillValidationMessage className="form-fill__validation">
      {validation}
    </FormFillValidationMessage>
  </Form>
)
