import React from 'react'
import { Spinner, Centered } from './components'

const UploaderSpinner = () => (
  <Centered>
    <Spinner />
  </Centered>
)

export default UploaderSpinner
