import axios from 'axios'
import { API } from './api'
import { MODAL_ERRORS_CODE } from '../helpers/constants'
import {
  pandemics,
  provinces,
  notification,
  nationalities,
  doctorSpecialties,
  documentValidation,
  healthInsurances,
  doctors,
  PCRTestAvailability,
  generateImageUploadUrl,
} from './endpoints'

const {
  GET_DOCTORS,
  GET_PROVINCES,
  GET_HEALTH_INSURANCES,
  GET_NATIONALITIES
} = MODAL_ERRORS_CODE;

export const amaditaApi = {
  fetchDoctors: () => API.get(doctors).catch(e => { throw GET_DOCTORS }),
  fetchProvinces: () => API.get(provinces).catch(e => { throw GET_PROVINCES }),
  fetchHealthInsurances: () => API.get(healthInsurances).catch(e => { throw GET_HEALTH_INSURANCES }),
  fetchDoctorSpecialties: () => API.get(doctorSpecialties),
  fetchNationalities: () => API.get(nationalities).catch(e => { throw GET_NATIONALITIES }),
  verifyDocument: params => API.get(documentValidation, params),
  sendFormCovid: data => API.post(pandemics, data),
  subscribeEmail: data => API.post(notification, data),
  checkPCRAvailability: params => API.get(PCRTestAvailability, params),
  getImageUploadUrl: params => API.get(generateImageUploadUrl, params),
}

export const awsApi = {
  uploadImages: (url, images, config) => axios.put(url, images, config),
}
