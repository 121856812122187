import React from 'react'
import constants from '../constants'
import { Container, Code } from './style'

export const CodeContainer = ({ code }) => (
  <Container>
    <p style={{ marginBottom: 8 }}>
      {constants.CODE_TITLE[0]}
      <strong>{constants.CODE_TITLE[1]}</strong>
      {constants.CODE_TITLE[2]}
    </p>
    <Code id="covidRequestCode">{code}</Code>
  </Container>
)
