import { addressModel } from '../../models/address'

export const isFormCompleted = formData => {
  let isCompleted = true
  for (const [key, value] of Object.entries(formData)) {
    if (
      key === 'isCompleted' ||
      key === 'isValid' ||
      key === 'provinces' ||
      key === 'isSectorManual'
    )
      continue
    if (!addressModel[key].isRequired) continue
    if (value.value === '' || value.value === undefined) {
      isCompleted = false
    }
  }

  return isCompleted
}
