export const ERROR = {
  EMPTY: {
    REQUIRED_INFO: 'Esta información es requerida',
  },
  INVALID: {
    LOCATION: 'Dirección inválida',
    STREET: 'Calle inválida',
    PROVINCE: 'Provincia inválida',
    MUNICIPALITY: 'Municipio inválido',
    SECTOR: 'Sector inválido',
    HOUSE_NUMBER: 'Número de casa o apartamento inválido',
  },
  GEOLOCATION_NOT_SUPPORTED: 'Este navegador no soporta la geolocalización.',
  GEOCODER_ERROR: 'Geocodificador falló debido a:',
}

export const SECTOR = {
  NOT_FOUND: 'Lo sentimos, no podemos encontrar el sector que buscas',
  ADD: 'Agregar este sector',
}

export const STREET_TYPE = 'route'
export const STREET_NUMBER_TYPE = 'street_number'
export const PROVINCE_TYPE = 'administrative_area_level_1'
export const MUNICIPALITY_TYPE = 'administrative_area_level_2'
