import React from 'react'
import { DividerContainer, TextContainer, Line } from './components'

const HorizontalDivider = props => {
  const { text } = props

  if (text) {
    return (
      <DividerContainer>
        <Line />
        <TextContainer>{text}</TextContainer>
        <Line />
      </DividerContainer>
    )
  }

  return <Line />
}

export default HorizontalDivider
