import React from 'react'
import { Description } from './Style'
import { MODAL } from './constants'
import { SimpleModal } from '../../'

const getMaxImagesModalBody = () => (
  <Description>{MODAL.MAX_IMAGES.BODY}</Description>
)

export const MaxImagesModal = ({ isOpen, onClose = () => {}, onClick }) => (
  <SimpleModal
    isOpen={isOpen}
    body={getMaxImagesModalBody()}
    title={MODAL.MAX_IMAGES.TITLE}
    buttonLabel={MODAL.MAX_IMAGES.BUTTON_LABEL}
    onCloseModal={onClose}
    onClick={onClick}
  />
)
