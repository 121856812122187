import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Compress from 'compress.js'
import HorizontalDivider from './components/HorizontalDivider'
import CloudUploadIcon from '../../assets/cloud-upload.svg'
import Constants from './constants'
import { mapCompressToUploader } from '../../helpers/util'

import {
  Label,
  UploaderBox,
  IconContainer,
  DividerContainer,
  FileListContainer,
  UploaderContainer,
} from './components'
import { Button } from '../Button'
import FileElement from './components/FileElement'
import UploaderSpinner from './components/Spinner'

export const Uploader = props => {
  const {
    fileLimit,
    onFilesDrop,
    onFileRemove,
    selectedFiles,
    acceptedFiles,
    width = '100%',
    onFileLimitReached,
    onFileTypeUnsupported,
    enableCompression,
  } = props

  const [isLoading, setLoading] = useState(false)

  const onDrop = async (files = []) => {
    setLoading(true)

    if (!enableCompression) {
      onFilesDrop(files)
      setLoading(false)
      return undefined
    }

    const compress = new Compress()
    const compressionProcess = files.map(async file => {
      if (file.type.includes('image')) {
        const compressionOptions = { size: 4, quality: 0.4 }
        const [compressedFile] = await compress.compress(
          [file],
          compressionOptions
        )

        return mapCompressToUploader(compressedFile)
      }

      return file
    })

    const formattedFiles = await Promise.all(compressionProcess)
    onFilesDrop(formattedFiles)
    setLoading(false)
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: fileLimit,
    accept: acceptedFiles,
  })

  useEffect(() => {
    if (fileRejections.length === 1) {
      onFileTypeUnsupported()
    }
    if (fileRejections.length > 1) {
      onFileLimitReached()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections])

  const renderLoading = () => <UploaderSpinner />

  const renderFileList = () => (
    <FileListContainer>
      {selectedFiles.map((selectedFile, index) => (
        <FileElement
          index={index}
          key={`${index}${new Date().getTime()}`}
          file={selectedFile}
          onFileRemove={onFileRemove}
        />
      ))}
    </FileListContainer>
  )

  const renderUploader = () => (
    <>
      <IconContainer>
        <img alt="Cloud Upload icon" src={CloudUploadIcon} />
      </IconContainer>
      <Label>{Constants.UPLOADER_TEXT}</Label>
      <DividerContainer>
        <HorizontalDivider text="o" />
      </DividerContainer>
      <Button outline>{Constants.BUTTON_LABEL}</Button>
    </>
  )

  return (
    <UploaderContainer>
      <UploaderBox style={{ width }} {...getRootProps()}>
        {isLoading
          ? renderLoading()
          : !selectedFiles || selectedFiles.length === 0
          ? renderUploader()
          : renderFileList()}
        <input {...getInputProps()} />
      </UploaderBox>
    </UploaderContainer>
  )
}
