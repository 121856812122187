import { documentsErrorValue } from './validation'

export const handleHealthInsuranceErrors = (field, value, triedToContinue) => {
  switch (field) {
    case 'idImage':
      return documentsErrorValue(value.data, triedToContinue)
    case 'insuranceImage':
      return documentsErrorValue(value.data, triedToContinue)
    case 'medicalIndicationImage':
      return documentsErrorValue(value.data, triedToContinue)
    default:
      return undefined
  }
}
