import React, { useContext, useRef } from 'react'
import constants from '../../../../helpers/constants'
import { useForm } from '../../../../hooks/useForm'
import { municipalityMapper } from '../../../../data/mappers/municipalityMapper'
import { zoneMapper } from '../../../../data/mappers/zoneMapper'
import { DataContext } from '../../../../context'
import { NoOptions } from '../../../../components/forms/Select/NoOptions'

// Components
import { Row, Col } from 'reactstrap'
import { StepWrapper } from '../../../../components/StepWrapper'
import { Field } from '../../../../components/forms/Field'
import { Select } from '../../../../components/forms/Select'
import { SECTOR } from './constants'
import { MenuList } from '../../../../components/List'

const { ADDRESS } = constants.FORMS


export const Address = ({ form }) => {
  const { data, onChange, setData } = useForm(form)
  const {
    zone,
    street,
    province,
    houseNumber,
    municipality,
    isSectorManual,
  } = data
  const { fetchedData } = useContext(DataContext)
  const { provinces } = fetchedData

  const zoneDropdownShouldShow =
    province?.value?.sectors !== undefined && isSectorManual.value === false

  const selectRef = useRef()

  const handleNoOptionsClick = () => {
    setData({
      zone: selectRef.current.state.inputValue,
      isSectorManual: true,
    })
    selectRef.current.blur()
  }

  return (
    <Row>
      <Col xs={12}>
        <StepWrapper title={ADDRESS} />
      </Col>
      <Col xs={12}>
        <Field
          validationMessage={street.error}
          label="Calle/Número"
          isRequired
          placeholder="Nombre de la calle, número"
          value={street.value}
          name="street"
          onChange={e => onChange(e)}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Select
          validationMessage={province.error}
          options={provinces}
          label="Provincia"
          placeholder="Selecciona"
          isRequired
          onChange={province => {
            setData({
              province,
              isSectorManual: false,
              zone: '',
              municipality: 0,
            })
          }}
          value={province.value}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Select
          validationMessage={municipality.error}
          options={province?.value?.cities?.map(municipalityMapper)}
          label="Municipio"
          placeholder="Selecciona"
          isRequired
          noOptionsMessage="Seleccione una provincia"
          onChange={municipality => setData({ municipality })}
          value={municipality.value}
        />
      </Col>
      {zoneDropdownShouldShow ? (
        <Col xs={12}>
          <Select
            label="Sector"
            isRequired
            placeholder="Zona por donde vives"
            options={province?.value?.sectors?.map(zoneMapper)}
            validationMessage={zone.error}
            ref={selectRef}
            name="sector"
            additionalComponents={{ MenuList }}
            forceDesktopComponent
            noOptionsMessage={
              <NoOptions
                onClick={handleNoOptionsClick}
                message={SECTOR.NOT_FOUND}
                buttonMessage={SECTOR.ADD}
              />
            }
            onChange={zone => setData({ zone })}
            value={zone.value}
          />
        </Col>
      ) : (
        <Col xs={12}>
          <Field
            validationMessage={zone.error}
            label="Sector"
            placeholder="Zona por donde vives"
            isRequired
            value={zone.value}
            name="zone"
            onChange={e => onChange(e)}
          />
        </Col>
      )}
      <Col xs={12}>
        <Field
          validationMessage={houseNumber.error}
          label="No. Casa/Apartamento"
          isRequired
          placeholder="Número"
          name="houseNumber"
          value={houseNumber.value}
          onChange={e => onChange(e)}
        />
      </Col>
    </Row>
  )
}
