/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react'
import { Form } from './screens/Form'
import { Home } from './screens/Home'
import { DataContext } from './context'
import constants, { MODAL_ERRORS_CODE } from './helpers/constants'
import { GlobalStyle, Theme } from './styles'
import { formModel } from './screens/Form/models'
import { ScreensContext } from './screens/Context'
import { amaditaApi, awsApi } from './services/amaditaCovidApi'
import { formSubmission } from './services/handlers'
import { provinceMapper, nationalityMapper } from './data/mappers'

export const App = () => {
  useEffect(() => {
    document.querySelector('html').style = {}
  }, [])
  const [screen, setScreen] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [fetchedData, setFetchedData] = useState({})
  const [formData, setFormData] = useState(formModel)
  const [isErrorModalOpen, setErrorModal] = useState(false)
  const [isBeforeThanksModalOpen, setBeforeThanksModal] = useState(false)

  const addFormData = useCallback(
    data => setFormData(formData => ({ ...formData, ...data })),
    [setFormData]
  )

  const setStepData = useCallback(
    data =>
      setFormData(formData => ({
        ...formData,
        steps: {
          ...formData.steps,
          ...data,
        },
      })),
    [setFormData]
  )

  const getPCRAvailability = async captchaResponse => {
    try {
      const PCRAvailabilityResponse = await amaditaApi.checkPCRAvailability({
        'g-recaptcha-response': captchaResponse,
      })
      const response = PCRAvailabilityResponse.data.pandemics
      const { isAvailable } = response
      return { isAvailable }
    } catch (e) {
      console.log(e)
      setErrorModal(MODAL_ERRORS_CODE.PCR_AVAILABILITY);
    }
  }

  const fetchAPIData = async () => {
    setLoading(true)

    try {
      const provincesResponse = await amaditaApi.fetchProvinces()
      const nationalitiesResponse = await amaditaApi.fetchNationalities()
      setFetchedData({
        provinces: provincesResponse.data.provinces.map(province =>
          provinceMapper(province)
        ),
        nationalities: nationalitiesResponse.data.nationalities.map(
          nationality => nationalityMapper(nationality)
        ),
      })
    } catch (code) {
      setErrorModal(code)
    }

    setLoading(false)
  }

  const uploadHealthInsuranceImages = async () => {
    const { idImage, insuranceImage, medicalIndicationImage } =
      formData.steps.healthInsurance
    const { hasInsurance } = formData.steps.beforeStart

    const imageObjArray = [idImage.value]

    if (hasInsurance.value) {
      imageObjArray.push(medicalIndicationImage.value, insuranceImage.value)
    }

    await Promise.all(uploadFiles(imageObjArray))
  }

  const uploadAntigenTestEvidence = async () => {
    const { hasInsurance, antigenEvidence } = formData.steps.beforeStart
    const antigenEvidenceValue = antigenEvidence.value

    const shouldUploadFile =
      hasInsurance.value &&
      antigenEvidenceValue &&
      antigenEvidenceValue.data &&
      antigenEvidenceValue.data.length

    if (!shouldUploadFile) return undefined

    await Promise.all(uploadFiles([antigenEvidence.value]))
  }

  const uploadFiles = files => {
    return files.map(async file => {
      const { name: filename, type, data } = file.data[0]
      const buffer = type.includes('image')
        ? Buffer.from(data, 'base64')
        : file.data[0]
      const response = await amaditaApi.getImageUploadUrl({ filename, type })
      const uploadUrl = response.data.pandemic.url
      const options = { headers: { 'Content-Type': type } }

      file.s3FileName = new URL(uploadUrl).pathname.substring(1)

      return awsApi.uploadImages(uploadUrl, buffer, options)
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const response = await formSubmission(formData)
    switch (response) {
      case constants.ERROR:
        setErrorModal(MODAL_ERRORS_CODE.SUBBMITTING_FORM)
        break
      default:
        const { code } = response.data.pandemics
        addFormData({ code })
        setBeforeThanksModal(true)
        break
    }
    setLoading(false)
  }

  const dataContextValues = {
    formData,
    setFormData,
    fetchedData,
    setStepData,
    handleSubmit,
    uploadHealthInsuranceImages,
    uploadAntigenTestEvidence,
  }

  const screensContextValues = {
    screen,
    setScreen,
    setErrorModal,
    isErrorModalOpen,
    setBeforeThanksModal,
    isBeforeThanksModalOpen,
  }

  const renderCurrentScreen = () => {
    switch (screen) {
      case 'form':
        return <Form isLoading={isLoading} setLoading={setLoading} />
      case 'thanks':
        return <Form isLoading={isLoading} setLoading={setLoading} />
      default:
        return (
          <Home
            fetchAPIData={fetchAPIData}
            getPCRAvailability={getPCRAvailability}
          />
        )
    }
  }

  return (
    <Theme>
      <GlobalStyle />
      <ScreensContext.Provider value={{ ...screensContextValues }}>
        <DataContext.Provider value={{ ...dataContextValues }}>
          {renderCurrentScreen()}
        </DataContext.Provider>
      </ScreensContext.Provider>
    </Theme>
  )
}
