import styled from 'styled-components'

export const FormFillRadioButton = styled.label`
  align-items: center;
  box-sizing: border-box;
  color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.blackOdd : colors.defaultTextColor};
  cursor: pointer;
  display: flex;
  font-size: 18px;
  height: 56px;
  line-height: 28px;
  margin: 0 0 16px 0;
  padding: 0 24px;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: auto;

  &::placeholder {
    color: ${({ theme: { colors } }) => colors.secondary};
  }

  &:focus {
    outline: none;
  }

  &:first-child {
    margin-right: 16px;
  }

  &:hover .form-fill-check,
  &:hover .form-fill-check-bg {
    border-color: ${({ theme, disabled }) =>
      !disabled && theme.colors.grayDark};
  }

  &.required {
    border-color: ${({ theme: { colors } }) => colors.required};
  }
`
export const FormFillRadioInput = styled.input`
  display: none;
  height: 0;
  width: 0;

  &[type='radio']:checked ~ .form-fill-check-icon .form-fill-check-active {
    opacity: 1;
  }

  &[type='radio']:checked ~ .form-fill-check-bg {
    border-color: ${({ theme: { colors }, disabled }) =>
      disabled ? colors.gray : colors.primary};
  }
`

export const FormFillCheckIcon = styled.div`
  display: block;
  height: 24px;
  margin-right: 16px;
  position: relative;
  width: 24px;
`

export const FormFillCheck = styled.div`
  border-radius: 50%;
  border: 2px solid ${({ theme: { colors } }) => colors.grayLight};
  display: block;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: 24px;
  z-index: 1;
`

export const FormFillCheckActive = styled.div`
  display: flex;
  height: 24px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: 24px;
  z-index: 2;
`

export const FormFillRadioLabel = styled.span`
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.grayDark : theme.colors.black};
  opacity: ${({ disabled, theme }) => disabled && theme.opacity.disabled};
`

export const FormFillCheckBg = styled.div`
  border-radius: 12px;
  border: solid 2px ${({ theme: { colors } }) => colors.grayLight};
  opacity: ${({ disabled, theme }) => disabled && theme.opacity.disabled};
  content: '';
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 1;
`
