import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid ${({ theme: { colors } }) => colors.grayLight};
  border-top: 4px solid ${({ theme: { colors } }) => colors.primary};
  border-radius: 50%;
  animation: ${loading} 0.9s linear infinite;
`

export const Centered = styled.div`
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  animation: ${animation} 0.3s;
`
