import { beforeStartModel } from './beforeStart'
import { getStartedModel } from './getStarted'
import { addressModel } from './address'
import { healthInsuranceModel } from './healthInsurance'
import { testModel } from './test'

export const STEPS_NAME = {
  BEFORE_START: 'beforeStart',
  GET_STARTED: 'getStarted',
  ADDRESS: 'address',
  HEALTH_INSURANCE: 'healthInsurance',
  TEST: 'test',
}

export const NUMBER_TO_STEP_NAME = [
  STEPS_NAME.BEFORE_START,
  STEPS_NAME.GET_STARTED,
  STEPS_NAME.ADDRESS,
  STEPS_NAME.HEALTH_INSURANCE,
  STEPS_NAME.TEST,
]

export const formModel = {
  steps: {
    [STEPS_NAME.BEFORE_START]: beforeStartModel,
    [STEPS_NAME.GET_STARTED]: getStartedModel,
    [STEPS_NAME.ADDRESS]: addressModel,
    [STEPS_NAME.HEALTH_INSURANCE]: healthInsuranceModel,
    [STEPS_NAME.TEST]: testModel,
  },
  hasChanges: false,
}
