import styled from 'styled-components'

export const UploaderBox = styled.div`
  display: flex;
  padding: 2em 40px;
  min-height: 264px;
  min-width: 290px;
  border-radius: 4px;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  font-family: ${props => props.theme.fonts.roboto};
  border: 1px dashed ${props => props.theme.colors.grayLight};

  &:hover {
    border: 1px dashed ${props => props.theme.colors.pink};
  }
`

export const Label = styled.span`
  font-size: 1rem;
  line-height: 1.5em;
  color: ${props => props.theme.colors.grayDark};
  margin-bottom: 1em;
`

export const IconContainer = styled.div`
  margin-top: 0.375em;
  margin-bottom: 1.25em;
`

export const DividerContainer = styled.div`
  margin-bottom: 1em;
`

export const FileListContainer = styled.div`
  height: 100%;
  display: flex;
  min-height: 189px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const UploaderContainer = styled.div`
  flex-grow: 1;
  display: flex;
  margin-bottom: 16px;

  button,
  button:hover,
  button:active,
  button:focus {
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    padding-left: 0;
    padding-right: 0;
    border-width: 1px !important;
  }
`

// File Element //

export const FileElementContainer = styled.div`
  display: flex;
  min-width: 100%;
  flex-direction: column;
  color: ${props => props.theme.colors.grayDark};
`

export const TopFileElement = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FileText = styled.div`
  width: 0;
  min-width: 86%;
`

export const FileName = styled.div`
  overflow: hidden;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.blackOdd};
`

export const RemoveFileButton = styled.img`
  cursor: pointer;
  margin-left: 16px;
`
