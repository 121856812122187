/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react'
import constants from '../../../../helpers/constants'
import { constants as stepConstants } from './constants'
import { useForm } from '../../../../hooks/useForm'
import { FormContext } from '../../Context'
import { STEPS_NAME } from '../../models'
import { isFormValid, handleFormErrors } from '../../../../helpers/form'

// Components
import { Step } from '../../../../components/Step'
import { getQuestions } from './questions'

export const isBeforeStartValid = formData =>
  isFormValid(formData, getQuestions(formData))

export const handleBeforeStartErrors = (field, value, triedToContinue, data) =>
  handleFormErrors(field, value, triedToContinue, data, getQuestions(data))

export const BeforeStart = ({ form }) => {
  const { BEFORE_START } = constants.FORMS
  const { data, setData } = useForm(form)
  const { visibleModal, setVisibleModal } = useContext(FormContext)
  const { setData: setGetStartedData } = useForm(STEPS_NAME.GET_STARTED)
  const { documentType, passport, id, isRequestForMinor } = data
  const questions = getQuestions(data, setData)

  useEffect(() => {
    setGetStartedData({
      documentType: documentType.value,
      passport: passport.value,
      id: id.value,
      isRequestForMinor: isRequestForMinor.value,
    })
  }, [documentType, passport, id, visibleModal, isRequestForMinor])

  const handleModalClose = () => {
    setVisibleModal('')
  }

  return (
    <Step
      title={BEFORE_START}
      questions={questions}
      stepConstants={stepConstants}
      visibleModal={visibleModal}
      handleModalClose={handleModalClose}
    />
  )
}
