import styled from 'styled-components'

export const Container = styled.div`
  border-top: solid 1px #e1e2e2;
  display: flex;
  margin-top: 16px;
  padding-top: 32px;
  justify-content: space-between;
  align-items: center;
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme: { media } }) => media.tablet`
    width: 100%;
  `}

  button {
    margin: 0 12px;

    ${({ theme: { media } }) => media.tablet`
      width: 50%;
      height: 56px;
      font-size: 16px;
    `}
  }

  button:first-child {
    margin-left: 0;
  }

  button:last-child {
    margin-right: 0;
  }
`
