import { UPLOADER } from './constants'

export class ConditionalMessage {
  constructor(isVisible, content) {
    this.isVisible = isVisible || (() => true)
    this.content = content
  }
}

class Option {
  constructor(label, id) {
    this.label = label
    this.id = id
  }
}

export class RadioOption extends Option {}

export class SelectOption extends Option {
  constructor(label, id) {
    super(label, id)
    this.value = id
  }
}

export const QuestionType = {
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkbox_group',
  DATEPICKER: 'datepicker',
  FIELD: 'field',
  FORMFILL: 'formfill',
  RADIO_GROUP: 'radio_group',
  RADIO: 'radio',
  SELECT: 'select',
  TEXTAREA: 'textarea',
  UPLOADER: 'uploader',
}

export class Question {
  constructor(params) {
    this.context = params.context
    this.name = params.name
    this.label = params.label
    this.description = params.description
    this.placeholder = params.placeholder
    this.isRequired = params.isRequired
    this.validations = params.validations
    this.options = params.options
    this.show = params.show
    this.messages = params.messages
    this.maxLength = params.maxLength

    this.value = params.value
    this.selected = params.value
    this.validation = params.validation
    this.validationMessage = params.validationMessage
    this.xs = params.xs || 'auto'
    this.sm = params.sm
    this.md = params.md
    this.lg = params.lg
    this.xl = params.xl
    this.type = params.type
    this.onChange = params.onChange || (() => {})

    if (params.type === QuestionType.DATEPICKER) {
      this.dateFormat = params.dateFormat
      this.showMonthYearPicker = params.showMonthYearPicker
      this.showFullMonthYearPicker = params.showFullMonthYearPicker || true
      this.showMonthDropdown = params.showMonthDropdown || true
      this.showYearDropdown = params.showYearDropdown || true
      this.scrollableYearDropdown = params.scrollableYearDropdown || true
      this.yearDropdownItemNumber = params.yearDropdownItemNumber || 25
      this.maxDate = params.maxDate
    }

    if (params.type === QuestionType.UPLOADER) {
      this.acceptedFiles = params.acceptedFiles
      this.selectedFiles = params.selectedFiles
      this.fileLimit = params.fileLimit || UPLOADER.FILELIMIT
      this.enableCompression = params.enableCompression
      this.onFilesDrop = params.onFilesDrop || (() => {})
      this.onFileRemove = params.onFileRemove || (() => {})
    }
  }

  isRadioGroup() {
    return this.type === QuestionType.RADIO_GROUP
  }

  isField() {
    return this.type === QuestionType.FIELD
  }

  isDatePicker() {
    return this.type === QuestionType.DATEPICKER
  }

  isSelect() {
    return this.type === QuestionType.SELECT
  }

  isUploader() {
    return this.type === QuestionType.UPLOADER
  }

  isValid() {
    if (!this.isVisible()) return true

    const { validations } = this

    if (validations && validations.length) {
      for (const valilation of validations) {
        const [isValid] = valilation(this.context, this.name)
        if (!isValid) return false
      }
    }

    return true
  }

  checkForErrors() {
    if (!this.isVisible()) return ''

    const { validations } = this

    if (validations && validations.length) {
      for (const valilation of validations) {
        const [isValid, message] = valilation(this.context, this.name)
        if (!isValid) return message
      }
    }

    return ''
  }

  isVisible() {
    return this.show == null || this.show === true
  }

  updateValue(newValue) {
    this.value = newValue
    this.selected = newValue
    this.context[this.name].value = newValue
  }
}
