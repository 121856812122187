import React from 'react'
import ReactCheckboxGroup from 'react-checkbox-group'
import { Checkbox as CheckBoxItem } from '../Checkbox'
import { FormFillCheckButtons } from './Style'

export const CheckBoxGroup = ({ list, value, name, onChange }) => (
  <ReactCheckboxGroup name="fruits" value={value} onChange={onChange}>
    {Checkbox => (
      <FormFillCheckButtons>
        {list.map(check => (
          <CheckBoxItem key={check.id} label={check.name}>
            <Checkbox value={check.id} name={name} id={check.id} />
          </CheckBoxItem>
        ))}
      </FormFillCheckButtons>
    )}
  </ReactCheckboxGroup>
)
