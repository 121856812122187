import React, { memo } from 'react'
import { Container, Title, Require } from './Style'

export const StepHeader = memo(({ children, required = true }) => (
  <Container>
    <Title>{children}</Title>
    {required && (
      <Require>
        Los campos marcados con <span> * </span> son requeridos
      </Require>
    )}
  </Container>
))
