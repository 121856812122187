export const sleep = async seconds =>
  new Promise(resolve => {
    setTimeout(resolve, seconds * 1000)
  })

export const retrier = (fn, limit = 3) => async (...args) => {
  let attemps = 0

  while (attemps <= limit) {
    try {
      return await fn.call(fn, ...args)
    } catch (e) {
      if (++attemps >= limit) throw e
      await sleep(2)
    }
  }
}

export const getISOStringDateFormat = date =>
  date && date.toISOString().split('T')[0]

export const partial = (callback, ...args) => (...moreArgs) =>
  callback(...args, ...moreArgs)
export const not = predicate => (...args) => !predicate(...args)
export const or = (...validators) => (...args) => {
  for (const validator of validators) {
    if (validator(...args)) return true
  }

  return false
}
export const and = (...validators) => (...args) => {
  for (const validator of validators) {
    if (!validator(...args)) return false
  }

  return true
}
export const valueOrDefault = (obj, path = '', defaultValue = undefined) => {
  const properties = path.split('.')

  const result = properties.reduce((value, property) => {
    if (value == null) return null

    return value[property]
  }, obj)

  return result != null ? result : defaultValue
}

export function mapCompressToUploader(file) {
  const mbInBytes = 1000000
  return {
    name: file.alt,
    path: file.alt,
    data: file.data,
    size: file.initialSizeInMb * mbInBytes,
    type: file.ext,
    compressedSize: file.endSizeInMb * mbInBytes,
  }
}

/**
 * @summary Formats a text from uppercase to lowercase and its first letter to uppercase.
 * @param {string} str
 * @returns {string}
 */
export const capitalize = str =>
  `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`

/**
 * @summary Use nationalities to get countries,
 * then do the country mapping as a label.
 *
 * @param {object} nationality
 * @returns {object}
 */
export const nationalitiesToCountries = ({ country, ...nationality }) => ({
  ...nationality,
  label: capitalize(country),
})
