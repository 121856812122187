export const provinces = `provinces`
export const doctorSpecialties = `doctors/specialties`
export const pandemics = `pandemics`
export const notification = `pandemics/notification`
export const documentValidation = `pandemics/document/validate`
export const healthInsurances = `pandemics/health-insurances`
export const doctors = `pandemics/doctors`
export const nationalities = `nationalities`
export const PCRTestAvailability = `pandemics/available`
export const generateImageUploadUrl = `pandemics/generate-url`
