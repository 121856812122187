import { yearDiff } from '../../../../helpers/dates'
import { not } from '../../../../helpers/util'
import { partial } from '../../../../helpers/util'
import { QuestionBuilder } from '../../../../helpers/question-builders'
import {
  isRequiredValidator,
  isValidCedulaValidator,
  isValidPassportValidator,
  isConditionSupported,
  isAntigenLessThan72Hours,
  isConditionSupportedValidator,
  isAntigenLessThan72HoursValidator,
  isAntigenTestedValidator,
} from './validation'
import {
  IS_MINOR_QUESTION,
  DOCUMENTTYPE_QUESTION,
  ID_QUESTION,
  PASSPORT_QUESTION,
  IS_INSURED_QUESTION,
  ANTIGEN_QUESTION,
  ANTIGEN_DATE_QUESTION,
  ANTIGEN_RESULT_QUESTION,
  ANTIGEN_CONDITION_QUESTION,
  ANTIGEN_EVIDENCE_QUESTION,
  IS_MINOR_ANSWER,
  IS_NOT_MINOR_ANSWER,
  CEDULA_ANSWER,
  PASAPORTE_ANSWER,
  YES_ANSWER,
  NO_ANSWER,
  POSITIVE_ANSWER,
  NEGATIVE_ANSWER,
  ASEGURADO_ANSWER,
  PRIVADO_ANSWER,
  CONDITION_ONE_ANSWER,
  CONDITION_TWO_ANSWER,
  CONDITION_THREE_ANSWER,
  ANTIGEN_TEST_MESSAGE,
  CONDITION_MESSAGE,
  EVIDENCE_MESSAGE,
  LABELS,
  DESCRIPTIONS,
  PLACEHOLDERS,
} from './constants'

export const getQuestions = (data, setData) => {
  const onChange = (field, value) => setData({ [field]: value })

  const questions = [
    new QuestionBuilder.RaidioGroup(IS_MINOR_QUESTION, data)
      .setLabel(LABELS.IS_REQUEST_FOR_MINOR)
      .setDescription(DESCRIPTIONS.IS_REQUEST_FOR_MINOR)
      .addOption(LABELS.YES, IS_MINOR_ANSWER)
      .addOption(LABELS.NO, IS_NOT_MINOR_ANSWER)
      .addValidator(isRequiredValidator)
      .isBoolean()
      .isTrueWhenSelectionIs(IS_MINOR_ANSWER),
    new QuestionBuilder.RaidioGroup(DOCUMENTTYPE_QUESTION, data)
      .setLabel(LABELS.DOCUMENTTYPE)
      .addOption(LABELS.CEDULA, CEDULA_ANSWER)
      .addOption(LABELS.PASSPORT, PASAPORTE_ANSWER)
      .addValidator(isRequiredValidator),
    new QuestionBuilder.Cedula(ID_QUESTION, data)
      .setLabel(LABELS.ID)
      .addValidator(isRequiredValidator)
      .addValidator(isValidCedulaValidator)
      .isVisibleIf(isDocumentNotPassport),
    new QuestionBuilder.Field(PASSPORT_QUESTION, data)
      .setLabel(LABELS.ID)
      .setPlaceholder(PLACEHOLDERS.PASSPORT)
      .addValidator(isRequiredValidator)
      .addValidator(isValidPassportValidator)
      .isVisibleIf(isDocumentPassport)
      .setMaxLength(44),
    new QuestionBuilder.RaidioGroup(IS_INSURED_QUESTION, data)
      .setLabel(LABELS.IS_INSURED)
      .addOption(LABELS.INSURED, ASEGURADO_ANSWER)
      .addOption(LABELS.PRIVATE, PRIVADO_ANSWER)
      .addValidator(isRequiredValidator)
      .isBoolean()
      .isTrueWhenSelectionIs(ASEGURADO_ANSWER),
    new QuestionBuilder.RaidioGroup(ANTIGEN_QUESTION, data)
      .setLabel(LABELS.ANTIGEN)
      .addOption(LABELS.YES, YES_ANSWER)
      .addOption(LABELS.NO, NO_ANSWER)
      .addValidator(isRequiredValidator)
      .addValidator(isAntigenTestedValidator)
      .isVisibleIf(isPatientInsured)
      .setSizes({ xs: 12 })
      .showMessageWhen(isPatientNotAntigenTested, ANTIGEN_TEST_MESSAGE),
    new QuestionBuilder.DatePicker(ANTIGEN_DATE_QUESTION, data)
      .setLabel(LABELS.ANTIGEN_DATE)
      .addValidator(isRequiredValidator)
      .addValidator(isAntigenLessThan72HoursValidator)
      .isVisibleIf(
        isPatientInsured,
        isPatientAntigenTested
      )
      .dontAllowFutureDates()
      .setSizes({ xs: 5 })
      .showMessageWhen(not(isAntigenLessThan72Hours), ANTIGEN_TEST_MESSAGE),
    new QuestionBuilder.Select(ANTIGEN_RESULT_QUESTION, data)
      .setLabel(LABELS.ANTIGEN_RESULT)
      .setPlaceholder(PLACEHOLDERS.SELECT)
      .addOption(LABELS.POSITIVE, POSITIVE_ANSWER)
      .addOption(LABELS.NEGATIVE, NEGATIVE_ANSWER)
      .addValidator(isRequiredValidator)
      .addValidator(isConditionSupportedValidator)
      .isVisibleIf(
        isPatientInsured,
        isPatientAntigenTested
      )
      .setSizes({ xs: 12 }),
    new QuestionBuilder.Select(ANTIGEN_CONDITION_QUESTION, data)
      .setLabel(LABELS.ANTIGEN_CONDITION)
      .setPlaceholder(PLACEHOLDERS.SELECT)
      .addOption(LABELS.CONDITION_ONE, CONDITION_ONE_ANSWER)
      .addOption(LABELS.CONDITION_TWO, CONDITION_TWO_ANSWER)
      .addOption(LABELS.CONDITION_THREE, CONDITION_THREE_ANSWER)
      .addValidator(isRequiredValidator)
      .addValidator(isConditionSupportedValidator)
      .isVisibleIf(
        isPatientInsured,
        isPatientAntigenTested
      )
      .showMessageWhen(not(isConditionSupported), CONDITION_MESSAGE)
      .setSizes({ xs: 12 }),
    new QuestionBuilder.Uploader(ANTIGEN_EVIDENCE_QUESTION, data)
      .setLabel(LABELS.ANTIGEN_EVIDENCE)
      .addValidator(isRequiredValidator)
      .isVisibleIf(
        isPatientInsured,
        isPatientAntigenTested,
        isAntigentResultAnswered,
        isAntigentConditionAnswered,
        isConditionSupported
      )
      .addSupportFor('.jpg', '.png', '.jpeg', '.pdf')
      .enableCompression()
      .setSizes({ xs: 6 })
      .showMessageWhen(isAntigentEvidenceAnswered, EVIDENCE_MESSAGE),
  ]

  return questions.map(q =>
    q.setOnChangeHandler(partial(onChange, q.name)).build()
  )
}

const isNil = value => value == null || value.length === 0
const isFileSelected = value =>
  !isNil(value) && !isNil(value.data) && value.data.length > 0
const isAntigentResultAnswered = ({ antigenResult }) =>
  !isNil(antigenResult.value)
const isAntigentConditionAnswered = ({ antigenCondition }) =>
  !isNil(antigenCondition.value)
const isAntigentEvidenceAnswered = ({ antigenEvidence }) =>
  isFileSelected(antigenEvidence.value)

const isDocumentNotPassport = ({ documentType }) =>
  documentType.value !== PASAPORTE_ANSWER
const isDocumentPassport = ({ documentType }) =>
  documentType.value === PASAPORTE_ANSWER
const isPatientInsured = ({ hasInsurance }) => hasInsurance.value === true
const isPatientAntigenTested = ({ isAntigenTested }) =>
  isAntigenTested.value === YES_ANSWER
const isPatientNotAntigenTested = ({ isAntigenTested }) =>
  isAntigenTested.value === NO_ANSWER
