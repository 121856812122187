import React from 'react'
import { Fade } from './Style'
import { StepHeader } from '../StepHeader'

export const StepWrapper = ({ title, children, hasRequiredFields }) => (
  <Fade>
    <StepHeader required={hasRequiredFields}>{title}</StepHeader>
    {children}
  </Fade>
)
