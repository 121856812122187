export const ERROR = {
  EMPTY: {
    REQUIRED_INFO: 'Esta información es requerida',
  },
  INVALID: {
    NAME: 'Debe introducir un nombre válido',
    LASTNAME: 'Debe introducir un apellido válido',
    SECOND_LAST_NAME: 'Debe introducir un segundo apellido válido',
    NUMBER: 'Número inválido',
    DATE: 'Fecha introducida es inválida',
    FUTURE_DATE: 'Fecha introducida es del futuro',
    NATIONALITY: 'Nacionalidad inválida',
    EMAIL: 'Correo inválido',
    PHONE: 'Celular inválido',
    TELEPHONE: 'Teléfono inválido',
    ID: 'Cédula inválida',
    PASSPORT: 'Pasaporte inválido',
    SSN: 'Número de seguridad social inválido',
  },
  NOT_FOUND: 'Lo sentimos, no podemos encontrar esta nacionalidad',
  CONFIRM_LATER: 'Confirmar más adelante',
}

export const YEAR_IN_SECONDS = 3.15576e10
export const PREGNANT_AGE = {
  MIN: 12,
  MAX: 60,
}
