/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

export const Prompt = ({ hasChanges }) => {
  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
    return () => window.removeEventListener('beforeunload', beforeUnload)
  }, [hasChanges])

  const beforeUnload = e => {
    if (hasChanges) {
      e.preventDefault()
      e.returnValue = true
    }
  }

  return null
}
