import React from 'react'

import { NO_TESTS_AVAILABLE_MODAL } from '../constants'
import { SimpleModal } from '../../../components/Modal'
import { Description } from './Style'

export const NoTestsAvailableModal = ({ onClick, isOpen, onCloseModal }) => {
  const getModalBody = () => (
    <>
      <Description>
        {NO_TESTS_AVAILABLE_MODAL.DESCRIPTION.FIRST}
        <strong>{NO_TESTS_AVAILABLE_MODAL.DESCRIPTION.SECOND}</strong>
        {NO_TESTS_AVAILABLE_MODAL.DESCRIPTION.THIRD}
        <br></br>
        <br></br>
        {NO_TESTS_AVAILABLE_MODAL.DESCRIPTION.FOURTH}
        <strong>{NO_TESTS_AVAILABLE_MODAL.DESCRIPTION.FIFTH}</strong>
        {NO_TESTS_AVAILABLE_MODAL.DESCRIPTION.SIXTH}
        <strong>{NO_TESTS_AVAILABLE_MODAL.DESCRIPTION.SEVENTH}</strong>
        {NO_TESTS_AVAILABLE_MODAL.DESCRIPTION.EIGHTH}
      </Description>
    </>
  )

  return (
    <SimpleModal
      title={NO_TESTS_AVAILABLE_MODAL.TITLE}
      body={getModalBody()}
      isOpen={isOpen}
      onClick={onClick}
      buttonLabel={NO_TESTS_AVAILABLE_MODAL.BUTTON_LABEL}
      onCloseModal={onCloseModal}
    />
  )
}
